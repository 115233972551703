import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

const BasicTextFields = ({
  isError,
  label,
  inputType,
  onDataChange,
  errortext,
  inputValue,
  disabled,
  isRequired,
  placeholder,
  max,
  inputRef,
  className,
  inputProps,
  tooltip,
  tooltipTitle,
  tooltipPlace
}) => {
  const formHelperTextStyles = `
  .MuiFormHelperText-root {
    margin-top: 0;
    margin-left: 0;
    position: absolute;
    font-size: 14px;
    color: #666666;
  }
`;
  return (
    <Box
      sx={{
        '& > :not(style)': { display: 'block' },
        '.error': {
          color: '#d32f2f!important'
        },
        '.MuiIcon-root': {
          float: 'right',
          marginBottom: '3px'
        }
      }}>
      <style>{formHelperTextStyles}</style>
      <Box>
        <label
          className={isError ? 'error' : null}
          htmlFor='outlined-basic'
          style={{
            fontFamily: 'Brandon Grostesque Bold',
            fontSize: '14px',
            margin: '3px 0px',
            color: '#333333'
          }}>
          {label} {isRequired ? <span style={{ color: '#ef4242' }}> *</span> : ''}
        </label>
        {tooltip ? <Tooltip PopperProps={{
          sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: '#666666',
                // width: '293px',
                // height: '82px',
                fontSize: '12px',
                position: 'relative', 
              }}
           }}
           title={tooltipTitle}
           placement={tooltipPlace} ><Icon><InfoOutlinedIcon></InfoOutlinedIcon></Icon> </Tooltip> : '' }
      </Box>
        <TextField
          value={inputValue ? inputValue : undefined}
          error={isError}
          id='outlined-basic'
          type={inputType ? inputType : 'text'}
          variant='outlined'
          onChange={onDataChange}
          autoComplete='off'
          fullWidth
          helperText={errortext}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ""}
          inputProps={{ maxLength: max, ...inputProps }}
          inputRef={inputRef}
          className={className}
        />
    </Box>
  );
};

export default BasicTextFields;
