import React from 'react';
import Header from '../../layouts/header';
import SideNavbar from '../../components/sideNavBar';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
  return (
    <>
      <Header></Header>
      <div style={{ display: 'flex', height: 'calc(100vh - 96px)' }}>
        <Grid container>
          <Grid item lg={2} md={2} xl={2} xs={1}>
            <SideNavbar />
          </Grid>
          <Grid item lg={10} md={10} xl={10} xs={11} style={{ flexGrow: 1, padding: '20px' }}>
            <Outlet />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AdminLayout;
