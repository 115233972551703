import axios from 'axios';
import { config } from '../config/config';

const generateCatalogue = (postData) => {
  return axios.post(`${config.api_url}/catalogue/generate`, postData, {}).then((res) => {
    return res.data;
  });
};

/**
 * POST API to get the product list with white spaces on preview.
 * @param {*} postData
 * @returns
 */
const generateStructure = (postData) => {
  return axios
    .post(`${config.api_url}/v2/catalogue/generate-structure`, postData, {})
    .then((res) => {
      return res.data;
    });
};

/**
 * Post API to get the html preview of the catalogue
 * @param {*} postData
 * @returns
 */
const generatePreviewV2 = (postData) => {
  return axios.post(`${config.api_url}/v2/catalogue/generate-preview`, postData, {}).then((res) => {
    return res.data;
  });
};

/**
 * POST API to start generation of catalogue.
 * @param {*} postData 
 * @returns 
 */
const generateCatalogueV2 = (postData) => {
  return axios.post(`${config.api_url}/v2/catalogue/generate`, postData, {}).then((res) => {
    return res.data;
  });
};

export default {
  generateCatalogue,
  generateStructure,
  generatePreviewV2,
  generateCatalogueV2
};
