import { createSlice } from '@reduxjs/toolkit';

export const previewSectionInitialState = {};

export const PreviewSectionSlice = createSlice({
  name: 'previewSection',
  initialState: previewSectionInitialState,
  reducers: {
    savePreviewSection: (_state, action) => ({
      ...previewSectionInitialState,
      ...action.payload
    }),
    resetPreviewSection: () => ({
      ...previewSectionInitialState
    })
  }
});

export const PreviewSectionAction = PreviewSectionSlice.actions;
export const PreviewSectionReducer = PreviewSectionSlice.reducer;
