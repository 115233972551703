import * as React from 'react';

export default function ReadMore({ renderedCellValue }) {
  if (renderedCellValue !== null && renderedCellValue.length > 95) {
    return (
      <>
        <span>
          {' ' + renderedCellValue.substring(0, 95) + ' '}
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0284c7' }}
            onClick={(e) => {
              e.target.parentNode.innerHTML = renderedCellValue;
              e.target.setAttribute('style', 'overflow: auto;');
            }}>
            {'  Read More '}
          </a>{' '}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span>{renderedCellValue}</span>
      </>
    );
  }
}
