import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

/**
 * This is used for Laptop / Monitor screen with Stepper active stepslabel 
 * @param {*} props 
 * @returns Stepper 
 */
const MobileStepperComponent = ({ value, stepList }) => {
    const activeStepValue = value + 1;
    const activeStepsLabel = stepList[value];
    const nextStepsLebel = stepList[activeStepValue];
    const totalTasks = stepList.length;
    return (
        <Box sx={{
            height: '56px',
            width: 'auto',
            boxSizing: 'border-box',
            borderBottom: '1px solid #e5e5e5',
            padding: '5px 30px',
            display: 'flex',
            alignItems: 'center',
        }}>
            <Box sx={{ position: 'relative', display: 'inline-flex', padding: '5px' }}>
                <CircularProgress variant="determinate" value={((activeStepValue) / totalTasks) * 100} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        padding: '5px',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {activeStepValue} of {stepList.length}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    marginLeft: '10px',
                    flexGrow: '4',
                    padding: '5px',
                }}>
                {activeStepsLabel}
            </Box>

            <Box
                sx={{
                    color: '#666666',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: '14px',
                    flexGrow: '3',
                }}>
                {activeStepValue !== 6 && (
                    <Typography variant="caption" component="div" color="text.secondary">
                        Next: {nextStepsLebel}
                    </Typography>)}
            </Box>
        </Box>
    );
};

export default MobileStepperComponent;