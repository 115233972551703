import axios from 'axios';
import { config } from '../config/config';

const getAllCatalogue = (filters) => {
  return axios.get(`${config.api_url}/allCatalogues?${filters}`).then((res) => {
    return res.data;
  });
};

const getSection = () => {
  return axios.get(`${config.api_url}/allCatalogueFilters/Section`).then((res) => {
    return res.data;
  });
};

const getBrand = () => {
  return axios.get(`${config.api_url}/allCatalogueFilters/Brands`).then((res) => {
    return res.data;
  });
};

const getSeason = () => {
  return axios.get(`${config.api_url}/allCatalogueFilters/Season`).then((res) => {
    return res.data;
  });
};

const getMarket = () => {
  return axios.get(`${config.api_url}/allCatalogueFilters/Market`).then((res) => {
    return res.data;
  });
};

const deleteCatalogueById = (catId) => {
  return axios.delete(`${config.api_url}/catalogue/`+ catId).then((res) => {
    return res;
  });
};

export default {
  getAllCatalogue,
  getSection,
  getBrand,
  getSeason,
  getMarket,
  deleteCatalogueById
};
