import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import LTBE_LOGO from '../assets/images/logo/ltbe.ico';

/**
 * NOTE:
 * If you set isInternalComponentLoader=true, assign position: 'relative' to the parent of loader component.
 * Without position: 'relative', isInternalComponentLoader=true will have no impact.
 *
 * @param {*} param0
 * @returns
 */
export default function SimpleBackdrop({ loading, isInternalComponentLoader = false }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    loading ? handleOpen() : null;
  }, []);

  return (
    <div>
      <Backdrop
        className='MuiBackdrop-Custom'
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: isInternalComponentLoader ? 'absolute' : ''
        }}
        open={open}>
        <div className='e-loadholder'>
          <div className='m-loader'>
            <span className='e-text'>
              <img className='clsImg' src={LTBE_LOGO} />{' '}
            </span>
          </div>
        </div>
        {/* <CircularProgress color='inherit' > </CircularProgress> */}
      </Backdrop>
    </div>
  );
}
