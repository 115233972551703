import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import theme from './config/theme';
import { StyledEngineProvider } from '@mui/material/styles';
//Import i18n.js
import './config/i18n';

// import http interceptor
import { AxiosInterceptor } from './interceptors/http';

// import redux store
import store from './store';
import { Notification } from './components/common/notification/notification';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <AxiosInterceptor>
              <App />
              <Notification />
            </AxiosInterceptor>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
