import { createSlice } from '@reduxjs/toolkit';

export const coversInitialState = {
  salesContact: '',
  salesContactEmail: '',
  coverFile: null,
  coverLogo: null,
  backCover: null,
  backLogo: null
};

export const CoverSlice = createSlice({
  name: 'cover',
  initialState: coversInitialState,
  reducers: {
    saveCoverDetails: (_state, action) => ({
      ...coversInitialState,
      ...action.payload
    }),
    resetCoverDetails: () => ({
      ...coversInitialState
    })
  }
});

export const CoverAction = CoverSlice.actions;
export const CoverReducer = CoverSlice.reducer;
