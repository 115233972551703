import { createSlice } from '@reduxjs/toolkit';

export const allSuggestedTemplatesState = [];

export const suggestedTemplatesSlice = createSlice({
  name: 'suggestedTemplate',
  initialState: allSuggestedTemplatesState,
  reducers: {
    saveAllSuggestedTemplates: (_state, action) => [
      ...allSuggestedTemplatesState,
      ...action.payload
    ],
    resetAllSuggestedTemplates: () => ({
      ...allSuggestedTemplatesState
    })
  }
});

export const AllSuggestedTemplatesAction = suggestedTemplatesSlice.actions;
export const AllSuggestedTemplatesReducer = suggestedTemplatesSlice.reducer;
