import { createSlice } from '@reduxjs/toolkit';

export const filtersInitialState = {
  selectedBrand: null,
  selectedSeason: null,
  selectedSection: null,
  selectedCategory: null,
  selectedSubCategory: null,
  selectedRange: null,
  selectedCountry: null,
  selectedWarehouse:null,
  startingPage: '',
  isStartingPageRight: null,
  title: '',
  showPrice: false,
  selectedPriceList: null,
  includeDiscontinuedProducts: false
};

export const FiltersSlice = createSlice({
  name: 'filters',
  initialState: filtersInitialState,
  reducers: {
    saveFilters: (_state, action) => ({
      ...filtersInitialState,
      ...action.payload
    }),
    resetFilterSection: () => ({
      ...filtersInitialState
    })
  }
});

export const TemplateFiltersAction = FiltersSlice.actions;
export const TemplateFiltersReducer = FiltersSlice.reducer;
