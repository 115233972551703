import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../features/login/login';
import AppWrapper from '../components/wrapper';
import GenerateCatalogue from '../features/catalogue/template-first/generate-catalogue/generateCatalogue';
import AllCatalogueList from '../features/list-catalogue/catalogueList';
import User from '../features/admin/users/users';
import AdminLayout from '../features/admin/admin';
import Seasons from '../features/admin/seasons';
import Countries from '../features/admin/countries';

export function Routing() {
  return (
    <Routes>
      <Route path='/' element={<AppWrapper />} />
      <Route path='/login' element={<Login />} />
      <Route path='/generate' element={<GenerateCatalogue />} />
      <Route path='/allCatalogues' element={<AllCatalogueList />} />
      <Route path="admin/*" element={<AdminLayout />}>
        <Route index element={< Navigate to="users" />} />
        <Route path="users" element={<User />} />
        <Route path="seasons" element={<Seasons />} />
        <Route path="countries" element={<Countries />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}
