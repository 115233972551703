import * as React from 'react';
import Box from '@mui/material/Box';
import EventComponent from '../features/event/event';
import Img from '../assets/images/logo/LOGO__LTBE BLACK.svg';
import { useNavigate } from 'react-router-dom';
import { logout } from '../features/login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../components/confirmDialog';
import localstorageService from '../services/localstorage.service';
import { DirtyCheckAction } from '../features/catalogue/template-first/generate-catalogue/dirtyCheck.slice';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton'; // Import IconButton
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import maintainanceService from '../services/maintainance.service';
import { config } from '../config/config';
import moment from 'moment/moment';
import 'moment-timezone';
import { UserRoles } from '../features/admin/users/enums/userRole';
import UpdateDataDialog from '../components/dialogs/updateDataDialog';
import { HttpStatusCode } from 'axios';
import { NotificationActions } from '../components/common/notification/notificationSlice';
import InfoDialog from '../components/dialogs/infoDialog';
import i18next from '../config/i18n';
import NavigationMenu from '../enums/menu';
import DataImportFailureDialog from './dataImportFailureDialog';

export default function Header({ resetStepper, setIsMaintenanceMode = null }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = React.useState('');
  const [nameInitials, setNameInitials] = React.useState('');
  const [isConfirmPopup, setConfirmPopup] = React.useState(false);
  const [eventType, setEventType] = React.useState('');
  const [activeMenu, setActiveMenu] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null); // State for menu anchor element

  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = React.useState('Never');
  const [lastFailedTimestamp, setLastFailedTimestamp] = React.useState(null);
  const dirtyCheckReducer = useSelector((state) => state.DirtyCheckReducer);
  const [openUpdateDataDialog, setOpenUpdateDataDialog] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [isMaintainanceFlag, setIsMaintenanceFlag] = React.useState(false);

  const navItems = [
    { key: NavigationMenu.GENERATE_CATALOGUE, value: i18next.t('menu.generateCatalogue') },
    { key: NavigationMenu.ALL_CATALOGUES, value: i18next.t('menu.allCatalogues') }
  ];

  /**
   * Handle Navigation based on selected type
   * @param {type}
   */
  const handleNavigation = (type) => {
    switch (type.trim()) {
      case 'Logout':
        handleLogout();
        break;
      case 'Admin':
        navigate('/admin');
        break;
      case NavigationMenu.ALL_CATALOGUES:
        navigate('/allCatalogues');
        break;
      case NavigationMenu.GENERATE_CATALOGUE:
        navigate('/generate');
    }
  };

  /**
   * Handler for confirmed actions of popup.
   */
  const onConfirm = () => {
    handleNavigation(eventType);
    if (eventType == NavigationMenu.GENERATE_CATALOGUE) resetStepper();
    // User has selected "Confirm" so reset dirty.
    dispatch(DirtyCheckAction.resetDirtyCheck());
    setConfirmPopup(false);
  };

  /**
   * Handle Click event based on selected button
   * @param {event}
   */
  const handleClick = (event, item) => {
    const buttonText = event.target.innerText;
    const isDirty = dirtyCheckReducer.isDirty;
    if (buttonText === 'Logout') {
      handleLogout(); // Handle logout separately
    } else if (![NavigationMenu.ALL_CATALOGUES, NavigationMenu.GENERATE_CATALOGUE].includes(item.key)) {
      // Open the menu
      setAnchorEl(event.currentTarget);
    }

    if (isDirty) {
      setConfirmPopup(true);
      setEventType(item.key);
    } else {
      handleNavigation(item.key);
    }
  };

  const lastUpdatedTimestampValue = async () => {
    try {
      let result = await maintainanceService.getLastUpdatedTimestamp();
      if (result.data.lastUpdatedTimestamp?.success) {
        const modifiedDate = moment(result.data.lastUpdatedTimestamp?.success)
          .tz(config.timeZone)
          .format('DD/MM/YYYY HH:mm');
        setLastUpdatedTimestamp(modifiedDate);
      }
      setLastFailedTimestamp(null);
      if (result.data.lastUpdatedTimestamp?.fail) {
        const modifiedDate = moment(result.data.lastUpdatedTimestamp?.fail)
          .tz(config.timeZone)
          .format('DD/MM/YYYY HH:mm');
        setLastFailedTimestamp(modifiedDate);
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  const getFullName = () => {
    let tempFullName =
      localstorageService.getUser().firstName + ' ' + localstorageService.getUser().lastName;
    let tempInitials =
      localstorageService.getUser().firstName.slice(0, 1) +
      localstorageService.getUser().lastName.slice(0, 1);
    setFullName(tempFullName);
    setNameInitials(tempInitials);
  };

  /**
   * Logout operations.
   */
  const handleLogout = () => {
    localstorageService.removeUser();
    dispatch(logout());
    navigate('/login');
  };

  function updateDataDialog() {
    setOpenUpdateDataDialog(true);
  }

  async function handleUpdateData() {
    try {
      const response = await maintainanceService.manuallyStartPublishActivity();
      setOpenUpdateDataDialog(false);

      if (response.success) {
        lastUpdatedTimestampValue();
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Data updating process started in background',
            type: 'success',
            timeout: 4000,
            typeStatus: 'Success'
          })
        );
      }
    } catch (err) {
      if (err?.response?.status === HttpStatusCode.Conflict) {
        setOpenInfoDialog(true);
      } else {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Error occured while updating the data, please try after sometime',
            type: 'error',
            timeout: 4000,
            typeStatus: 'Error'
          })
        );
      }
      setOpenUpdateDataDialog(false);
    }
    setAnchorEl(null);
  }

  React.useEffect(() => {
    lastUpdatedTimestampValue();

    // API call to get the flag value.
    maintainanceService.getMaintenanceStatus().then(
      (response) => {
        if (setIsMaintenanceMode) setIsMaintenanceMode(response.data.maintenanceMode);
        setIsMaintenanceFlag(response.data.maintenanceMode);
      },
      (error) => {
        console.error('Error while fetching maintanence status', error);
      }
    );

    if (window.location.href.includes('generate')) {
      setActiveMenu(0);
      getFullName();
    } else if (window.location.href.includes('admin')) {
      setActiveMenu(2);
    } else {
      setActiveMenu(1);
      getFullName();
    }
  });

  return (
    <>
      <DataImportFailureDialog />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          boxShadow: '0px 8px 10px #00000024',
          padding: '0px 30px',
          height: '56px',
          '.header-button-outlined': {
            padding: '4px 16px !important',
            minWidth: '120px',
            height: '36px !important',
            fontSize: '16px !important'
          },
          '@media screen and (max-width: 991px)': {
            '.header-img': {
              width: '176px !important'
            }
          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={Img} alt='Logo' className='header-img' style={{ width: '200px' }} />
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginLeft: 'auto',
            gap: '20px',
            '.admin-button': {
              minWidth: '96px !important',
              maxWidth: '96px !important'
            },
            '.MuiList-root': {
              height: '100%'
            },
            '.Mui-selected': {
              borderBottom: '4px solid #FFC845',
              backgroundColor: 'white !important'
            },
            '.MuiListItemButton-root': {
              height: '100%'
            },
            '.MuiTypography-root': {
              fontSize: '16px',
              fontWeight: '900'
            },
            '.MuiListItem-root': {
              padding: '8px 16px'
            },
            '.MuiButtonBase-root': {
              padding: '0px'
            }
          }}>
          <List sx={{ display: 'flex' }}>
            {navItems.map((item, index) => (
              <ListItem
                key={item.key}
                disablePadding
                sx={{ width: 'max-content' }}
                className={index === activeMenu ? 'Mui-selected' : ''}>
                <ListItemButton
                  sx={{
                    textAlign: 'center',
                    color: '#333333',
                    letterSpacing: '0.4px',
                    fontWeight: '900'
                  }}
                  onClick={(event) => handleClick(event, item)}>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Box
            sx={{
              '.circular-button': {
                // Applied the circular button styles here
                position: 'relative',
                width: '40px',
                height: '40px',
                background: '#FFC845',
                border: '2px solid #FFC845',
                borderRadius: '50%', // Make it circular
                opacity: '100%'
              },
              '.nameInitials-label': {
                fontSize: '20px',
                textAlign: 'left',
                opacity: '1',
                color: '#333333',
                font: 'Brandon Grotesque',
                fontStyle: 'Regular',
                letterSpacing: '0px'
              }
            }}>
            <IconButton
              className='circular-button'
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              <Typography className='nameInitials-label'>{nameInitials}</Typography>
            </IconButton>
            <Box>
              <Menu
                id='menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                <MenuItem sx={{ '&:hover': { backgroundColor: 'white', cursor: 'text' } }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      textAlign: 'left',
                      opacity: '1',
                      color: '#333333'
                    }}>
                    {fullName}
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{
                    '&:hover': { backgroundColor: 'white', cursor: 'text' },
                    paddingTop: '0px'
                  }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      textAlign: 'left',
                      opacity: '1',
                      fontWeight: 'bold',
                      color: '#5cb8b2',
                      letterSpacing: '0px',
                      font: 'Brandon Grotesque',
                      fontStyle: 'italic'
                    }}>
                    Last Updated: {lastUpdatedTimestamp}
                  </Typography>
                </MenuItem>
                {lastFailedTimestamp && <MenuItem
                  sx={{
                    '&:hover': { backgroundColor: 'white', cursor: 'text' },
                    paddingTop: '0px',
                  }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      textAlign: 'left',
                      opacity: '1',
                      fontWeight: 'bold',
                      color: '#ef4242',
                      letterSpacing: '0px',
                      font: 'Brandon Grotesque',
                      fontStyle: 'italic'
                    }}
                    className='success'>
                    Data Import Error: {lastFailedTimestamp}
                  </Typography>
                </MenuItem>}
                <Divider /> {/* Add a line separator here */}
                {/* Conditionally render the "Update Data Now" button based on some condition */}
                {[UserRoles.SUPERADMIN, UserRoles.ADMIN].includes(
                  localstorageService.getUser().role
                ) ? (
                  <MenuItem disabled={isMaintainanceFlag} onClick={() => updateDataDialog()}>
                    <RefreshIcon />
                    <Typography
                      sx={{
                        font: 'Brandon Grotesque',
                        fontStyle: 'Regular',
                        fontSize: '16px',
                        letterSpacing: '0px',
                        color: '#333333',
                        opacity: '1',
                        textAlign: 'left',
                        paddingLeft: '10px'
                      }}>
                      Update Data Now
                    </Typography>
                  </MenuItem>
                ) : null}
                {[UserRoles.SUPERADMIN, UserRoles.ADMIN].includes(
                  localstorageService.getUser().role
                ) ? (
                  <Divider />
                ) : null}
                <MenuItem onClick={() => handleNavigation('Logout')}>
                  <LogoutIcon
                    sx={{
                      background: `transparent url('Img/10 - 133.png') 0% 0% no-repeat padding-box`,
                      opacity: '1',
                      color: '#EF4242'
                    }}
                  />
                  <Typography
                    sx={{
                      font: 'Brandon Grotesque',
                      fontStyle: 'Regular',
                      fontSize: '16px',
                      letterSpacing: '0px',
                      color: '#EF4242',
                      opacity: '1',
                      textAlign: 'left',
                      paddingLeft: '10px'
                    }}>
                    {' '}
                    Logout{' '}
                  </Typography>{' '}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
      <EventComponent />
      <ConfirmDialog
        open={isConfirmPopup}
        handleClose={() => {
          setConfirmPopup(false);
        }}
        handleConfirm={onConfirm}
      />
      <UpdateDataDialog
        open={openUpdateDataDialog}
        handleClose={() => {
          setOpenUpdateDataDialog(false);
          setAnchorEl(null);
        }}
        handleUpdateData={handleUpdateData}
      />
      <InfoDialog
        open={openInfoDialog}
        handleClose={() => {
          setOpenInfoDialog(false);
        }}
        dialogTitle='Information'
        dialogContentMessage='Data updating process is already in progress.'
      />
    </>
  );
}
