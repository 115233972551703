import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import { DialogActions,DialogTitle, DialogContent } from '@mui/material';

const ValidationDialog = (props) => {
  const { open, closeValidationDialog, enteredSKU, isDuplicateSKU, validationMessage } = props;

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          fontSize: '16px !important',
          marginTop: '16px',
          padding: '7px 16px',
          minWidth: '96px !important',
          height: '36px !important',
        },
        '.MuiPaper-root': {
          minWidth: '400px'
        }
      }}
    > { (isDuplicateSKU === false) && 
            <div style={{ padding: '16px 24px', textAlign: 'center' }}>
                <div style={{ margin: '30px auto 20px auto', textAlign: 'center' }}>
                    <HighlightOffIcon style={{ height: '75px', width: '75px' }}></HighlightOffIcon>
                </div>
                <Typography variant='h2' style={{ marginBottom: '20px', color: 'red' }}>
                    {validationMessage}
                </Typography>
                <Typography variant='p' style={{ marginBottom: '6px' }}>
                    <b>Entered SKU: </b> {enteredSKU}
                </Typography>
                <Grid container>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <ButtonComponent
                        variant='contained'
                        handleClick={closeValidationDialog}
                        buttonText='Update SKU'
                        className='dialog-button'
                        btnSize="small"
                        ></ButtonComponent>
                    </Grid>
                </Grid>
            </div>
        } 
        { (isDuplicateSKU === true) && 
            <div>
                <DialogTitle>
                    <Typography variant='h2'>
                        Duplicate Product
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='p' style={{ marginBottom: '6px' }}>
                        Product already exists in the product list.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ padding: '0 16px 16px 16px'}}>
                    <Grid container spacing={1}>
                        <Grid item style={{ marginLeft: '5px' }}>
                            <ButtonComponent
                            variant='contained'
                            handleClick={closeValidationDialog}
                            buttonText='cancel'
                            className='dialog-button'
                            btnSize="small"
                            ></ButtonComponent>                            
                        </Grid>
                    </Grid>
                </DialogActions>               
            </div>
        }
    </Dialog>
  );
};

export default ValidationDialog;
