import axios from 'axios';
import { config } from '../config/config';
import { WarehouseKeys } from '../features/catalogue/template-first/warehouse.constants';

const DISCONTINUED_STATUS_CODES = [21, 22, 23];

const checkForDiscontinuedProducts = (product, warehouse) => {
  try {
    const key = warehouse === WarehouseKeys.EUWH ? 'StatusCodeEU' : 'StatusCodeUK';
    return DISCONTINUED_STATUS_CODES.includes(product[key]);
  } catch (err) {
    console.error(err);
  }
};

const getProducts = (filters) => {
  return axios.post(`${config.api_url}/products`, filters).then((res) => {
    return res.data;
  });
};

export default {
  getProducts,
  checkForDiscontinuedProducts
};
