import React, { useEffect, useMemo, useState } from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, IconButton, Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MaterialReactTable } from 'material-react-table';
import SimpleBackdrop from '../../../components/loader';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '../../../components/common/notification/notificationSlice';
import ButtonComponent from '../../../components/button';
import usersService from '../../../services/users.service';
import StatusDialog from './dialogs/statusDialog';
import { UserRoles } from './enums/userRole';

const Users = () => {

  //all state
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [userData, setUserData] = useState({});

  //get all user informations
  const getUserList = async () => {
    setLoading(true);
    let userListResponse = await usersService.list();
    setUsersList(userListResponse);
    setLoading(false);
  }

  //to dispath the event
  const dispatch = useDispatch();

  // call on page loading
  useEffect(() => {
    getUserList()
  },[])


  //Called when Activate/Deactivate button click from confirm modal
  const statusChangeEvent = async () => {
    const resetActiveStatus = {
      isDeleted: userData.userStatus ? false : true,
      id: userData.userID
    };
    let userStatusResponse = await usersService.updateUserStatus(resetActiveStatus);
    getUserList();
    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: (userStatusResponse ? "User" + (userData.userStatus ? " activated " : " deactivated ") +  " successfully" : 'Something went wrong!' ) ,
        type: (userStatusResponse ? 'success' : 'error' ),
        timeout: 6000,
        typeStatus: (userStatusResponse ? 'Success' : 'Error' )
      })
    );
  }

  // calling active column to reset the active status
  const handleStatusEvent = async (event, userStatus, userID, fName, lName) => {
    setOpenStatusDialog(true);
    setUserData({ userID: userID, userStatus: userStatus, userName: fName + ' ' + lName })
  }

  //calling reset password api
  const handleResetEvent = () => {
    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: "New password sent successfully on the user's email",
        type: 'success',
        timeout: 6000,
        typeStatus: 'Success'
      })
    )
  }

  //open edit popup
  const handleOpenEditDialog = () => {

  }

  //chagne status dialog
  const statusChangeDialog = () => {
    statusChangeEvent();
    setOpenStatusDialog(false);
  }

  //close status dialog
  const closeStatusDialog = () => {
    setOpenStatusDialog(false)
    setUserData({});
  }

  // user column list
  const userColumns = useMemo(() => [
    {
      accessorKey: 'isDeleted', //access nested data with dot notation
      header: 'Active',
      size: 80,
      enableColumnFilter: false,
      Cell: ({renderedCellValue, row}) => (
        <div>{ row.original.role != UserRoles.SUPERADMIN ?        
          <IconButton>
            <Switch color="primary" checked={renderedCellValue ? false : true} onChange={(e) => {
              handleStatusEvent(e, renderedCellValue, row.original.id, row.original.firstName, row.original.lastName);
            }}/>
          </IconButton>
        : <Box sx={{ height: "50px" }}></Box> }
        </div>
      ),
    },
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 150,
      filterVariant: 'text',
      filterFn: 'contains',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Firstname' },
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
      size: 150,
      filterVariant: 'text',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Lastname' },
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 350,
      filterVariant: 'text',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Email' },
    },
    {
      accessorKey: 'role',
      accessorFn: (row) => row.role === "SUPERADMIN" ? UserRoles.SUPERADMIN : row.role ,
      header: 'Role Assigned',
      size: 150,
      filterVariant: 'select',
      filterSelectOptions: Object.values(UserRoles),
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Role' },
      
    },
    {
      accessorKey: 'resetpassword',
      header: 'Reset Password',
      size: 50,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div> {row.original.role != UserRoles.SUPERADMIN ? 
          <IconButton>
            <LockResetIcon  onClick={handleResetEvent}/>
          </IconButton>
          : <Box sx={{ height: "50px" }}></Box> }
        </div>
      )
    },
    {
      accessorKey: 'edit',
      header: 'Edit',
      size: 50,
      enableColumnFilter: false,
      Cell: ({row}) => (
        <div> {row.original.role != UserRoles.SUPERADMIN ? 
          <IconButton>
            <EditOutlinedIcon onClick={handleOpenEditDialog} />
          </IconButton>
        : <Box sx={{ height: "50px" }}></Box> }
        </div>
      )
    }
  ]);

  return (
    <Box sx={{ padding: '20px' }}>
      {loading && <SimpleBackdrop loading={loading} />}
       <Box
            sx={{
              '.MuiPaper-root': {
                boxShadow: 'none'
              },
              '.MuiTableContainer-root': {
                borderRadius: '4px',
                maxHeight: '64vh'
              },
              '.MuiCollapse-root': {
                background: '#ffffff',
                padding: '5px',
                border: '1px solid #e5e5e5',
                borderRadius: '4px',
                marginTop: '10px'
              },
              '.MuiTableCell-root': {
                '&:nth-of-type(6), &:nth-of-type(7)':
                {
                  textAlign: 'center'
                },
                verticalAlign: 'top',
                padding: '8px'
              },
              '.Mui-TableHeadCell-Content': {
                justifyContent: 'center'
              },
              '@media screen and (max-width: 1120px)': {
                'MuiTableContainer-root': {
                  overflowX: 'scroll'
                }
              },
              '.MuiButtonBase-root': {
                margin: '0px 0px 5px 2px'
              },
              '.Mui-TableHeadCell-Content-Wrapper': {
                minWidth: '0ch'
              },
            }}
          >
            <Grid item="true" xl={12} lg={12} sm={12} sx={{ justifyContent: 'end', display: 'flex', '.addNewUser-button' : {
                      width: '160px !important',
                      height: '46px !important'
                    } }} marginBottom={{ md: 0, sm: 0, lg: 1, xl: 1 }}>
            <ButtonComponent
              variant='contained'
              buttonText="Add New User"
              btnSize="small"
              className='addNewUser-button'
             />
            </Grid>
            <MaterialReactTable
              columns={userColumns}
              data={usersList}
              selectAllMode='all'
              enableStickyHeader={true}
              enableColumnActions={false}
              enableSorting={false}
              enableTopToolbar={false}
              enableFacetedValues
              initialState={{
                showColumnFilters: true
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  fontFamily: 'Brandon Grostesque Regular',
                  fontSize: '16px' 
                }
              }}
              muiTableHeadCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  background: '#f1f1f1',
                  fontFamily: 'Brandon Grostesque Bold',
                  fontSize: '16px'
                }
              }}
              getRowId={(originalRow) => originalRow.id}
              muiTableBodyRowProps={({row}) => ({
                sx: {
                  backgroundColor: (row.original.role === UserRoles.SUPERADMIN ? "#0284C71A" : "" ), //highlight the row for super admin
                  '&:hover': {
                    backgroundColor: "",
                  }
                }
              })}
            />
          </Box>
      <StatusDialog
          open={openStatusDialog}
          closeStatusDialog={closeStatusDialog}
          statusChangeDialog={statusChangeDialog}
          UserName={userData.userName}
          isActivate={userData.userStatus}
      ></StatusDialog>
    </Box>
  );
};
export default Users;
