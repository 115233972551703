import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, styled } from '@mui/material';
import Box from '@mui/material/Box';
import BasicTextFields from '../../components/textbox';
import ButtonComponent from '../../components/button';
import BasicAlerts from '../../components/alert';
import SimpleBackdrop from '../../components/loader';
import { login } from './loginSlice';
import localstorageService from '../../services/localstorage.service';

import { CoverAction } from '../catalogue/template-first/cover/selectCover.slice';
import { AllProductAction } from '../catalogue/template-first/select-product/allProductList.slice';
import { SelectedProductAction } from '../catalogue/template-first/select-product/selectedProductList.slice';
import { SelectedProductCodeAction } from '../catalogue/template-first/select-product/selectedProductCodeList.slice';
import { TemplateFiltersAction } from '../catalogue/template-first/generate-catalogue/filterSection.slice';
import { TemplateAction } from '../catalogue/template-first/select-template/template.slice';
import { SequenceProductAction } from '../catalogue/template-first/sequence-product/sequenceProducts.slice';
import { PreviewSectionAction } from '../catalogue/template-first/preview-catalogue/previewSection.slice';

const FullWidthContainer = styled('div')(() => ({
  width: '100%',
  height: '100vh',
  boxSizing: 'border-box',
  backgroundColor: '#f2f2f2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export default function Login() {
  const [alertMessage, setAlertMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    localstorageService.getUser().email? navigate('/generate') : null;
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        clearState();
        navigate('/generate');
      })
      .catch(() => {
        setLoading(false);
        setAlertMessage('Login Failed! Invalid email or password ');
      });
  };

  const handleUsername = (e) => {
    const { value } = e.target;
    setUsername(value.toLowerCase());
  };

  const clearState = () => {
      // Clearing the state from redux.
      dispatch(CoverAction.resetCoverDetails());
      dispatch(SelectedProductAction.resetSelectProducts());
      dispatch(SequenceProductAction.resetSequence());
      dispatch(AllProductAction.resetAllProducts());
      dispatch(SelectedProductCodeAction.resetSaveCode());
      dispatch(TemplateFiltersAction.resetFilterSection());
      dispatch(TemplateAction.resetTemplateSection());
      dispatch(PreviewSectionAction.resetPreviewSection())
  }

  const handlePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <FullWidthContainer>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          padding: '50px 70px 150px 70px',
          height: 400,
          width: 550,
          backgroundColor: '#ffffff',
          boxShadow: ' 0px 24px 38px #0000003D',
          borderRadius: '4px'
        }}
      >
        {/* <form autoComplete='off' onSubmit={handleSubmit}> */}
        <Grid container spacing={2} direction='column' alignItems='center' justifyContent='center'>
          <Grid item>
            <img
              src={require('../../assets/images/logo/LOGO__LTBEBLACK.png')}
              alt='Logo'
              style={{
                width: '450px',
                height: '130px'
              }}
            />
          </Grid>
          {alertMessage && (
            <Grid item sx={{ width: '425px' }}>
              <BasicAlerts severity='error' message={alertMessage} />
            </Grid>
          )}

          <Grid item sx={{ width: '425px' }}>
            <BasicTextFields 
              label='Email' 
              onDataChange={handleUsername} 
              placeholder="Email"
              isRequired={true}
            ></BasicTextFields>
          </Grid>
          <Grid item sx={{ width: '425px' }}>
            <BasicTextFields
              label='Password'
              inputType='password'
              onDataChange={handlePassword}
              isRequired={true}
              placeholder="Password"
            ></BasicTextFields>
          </Grid>
          <Grid item sx={{ width: '415px', margin: '40px auto' }}>
            <ButtonComponent
              variant='contained'
              buttonText='Login'
              type='submit'
              disabled={!(username && password)}
            ></ButtonComponent>
            {loading && <SimpleBackdrop loading={loading} />}
          </Grid>
        </Grid>
        {/* </form> */}
      </Box>
    </FullWidthContainer>
  );
}
