import { combineReducers } from 'redux';
import { LoginReducer } from './features/login/loginSlice';
import { NotificationReducer } from './components/common/notification/notificationSlice';
import { CoverReducer } from './features/catalogue/template-first/cover/selectCover.slice.js';
import { AllProductReducer } from './features/catalogue/template-first/select-product/allProductList.slice';
import { SelectedProductReducer } from './features/catalogue/template-first/select-product/selectedProductList.slice';
import { SelectedProductCodeReducer } from './features/catalogue/template-first/select-product/selectedProductCodeList.slice';
import { AllSuggestedTemplatesReducer } from './features/catalogue/template-first/select-product/templateList.slice';
import { TemplateReducer } from './features/catalogue/template-first/select-template/template.slice';
import { DirtyCheckReducer } from './features/catalogue/template-first/generate-catalogue/dirtyCheck.slice';
import { SequenceProductReducer } from './features/catalogue/template-first/sequence-product/sequenceProducts.slice';
import { PreviewSectionReducer } from './features/catalogue/template-first/preview-catalogue/previewSection.slice';
import { TemplateFiltersReducer } from './features/catalogue/template-first/generate-catalogue/filterSection.slice';

// Combine all different reducers
const appReducer = combineReducers({
  LoginReducer,
  NotificationReducer,
  AllProductReducer,
  SelectedProductReducer,
  SelectedProductCodeReducer,
  CoverReducer,
  AllSuggestedTemplatesReducer,
  TemplateReducer,
  DirtyCheckReducer,
  SequenceProductReducer,
  PreviewSectionReducer,
  TemplateFiltersReducer
});

const rootReducer = (state, action) => {
  if (action.type.includes('logout')) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
