import { createSlice } from '@reduxjs/toolkit';

export const selectedProductCodeInitialState = [];

export const SelectedProductCodeSlice = createSlice({
  name: 'selectedproductcode',
  initialState: selectedProductCodeInitialState,
  reducers: {
    saveCode: (_state, action) => ({
      ...selectedProductCodeInitialState,
      ...action.payload
    }),
    resetSaveCode: () => ({
      ...selectedProductCodeInitialState
    })
  }
});

export const SelectedProductCodeAction = SelectedProductCodeSlice.actions;
export const SelectedProductCodeReducer = SelectedProductCodeSlice.reducer;
