import { Box, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonComponent from '../../../../components/button';
import SimpleBackdrop from '../../../../components/loader';
import { MaterialReactTable } from 'material-react-table';
import catalogueService from '../../../../services/catalogue.service';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import PreviewBlankSpaceDialog from './dialogs/PreviewBlankSpaceDialog';
import { RefreshOutlined } from '@mui/icons-material';
import ResetDialog from './dialogs/ResetDialog';
import { BlankSpaceColors, ProductType } from './constants/previewCatalogue.constants';
import ReadMore from '../../../../components/readMore';
import GridHeaderComponent from '../../../../components/gridHeader';

const ProductsStructure = ({
  handleSaveChanges,
  setIsGenerateCatalogueDisabled,
  isSaveChangesDisable,
  setIsSaveChangesDisable
}) => {
  const [loading, setLoading] = useState(false);
  const [previewProductsList, setPreviewProductsList] = useState([]);
  const [openPreviewBlankSpaceDialog, setOpenPreviewBlankSpaceDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [openResetDialog, setOpenResetDialog] = useState(false);

  const savedData = useSelector((state) => state);
  const savedProductList = Object.values(savedData.SequenceProductReducer);
  const selectedTemplate = savedData.TemplateReducer;
  const previewSectionData = savedData.PreviewSectionReducer;
  const dispatch = new useDispatch();

  /**
   * Fetch products from server and load into the grid.
   */
  const loadProducts = async () => {
    setLoading(true);
    try {
      const requestGenerateStructure = {
        templateGridConfig: selectedTemplate.gridConfig,
        productData: savedProductList
      };
      const response = await catalogueService.generateStructure(requestGenerateStructure);
      setPreviewProductsList(response.data);

      // Auto load preview on first time / component load.
      handleSaveChanges(response.data);
    } catch (err) {
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Something went wrong, please try again later.',
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Save button validations and action
   */
  const saveChangesAction = async () => {
    // TODO: validations before save.

    // forwarding products to parent component for further process.
    handleSaveChanges(previewProductsList);
  };

  /**
   * Component on load handler.
   */
  useEffect(() => {
    // Back from cover pages, load products from redux.
    if (previewSectionData.previewProducts && previewSectionData.html72dpi) {
      setPreviewProductsList(previewSectionData.previewProducts);

      // Auto load preview on first time / component load.
      handleSaveChanges(previewSectionData.previewProducts);
      return;
    }

    loadProducts();
  }, []);

  //open edit dialogbox
  const handleOpenEditDialog = (rowData) => {
    setCurrentRow(rowData);
    setOpenPreviewBlankSpaceDialog(true);
  };

  const handleCloseBlankSpaceDialog = () => {
    setOpenPreviewBlankSpaceDialog(false);
  };

  /**
   * open reset dialogbox
   *
   * @param {*} rowData
   */
  const handleOpenResetDialog = (rowData) => {
    setCurrentRow(rowData);
    setOpenResetDialog(true);
  };

  /**
   * Close action of Edit Popup.
   */
  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  /**
   * Reset row action of grid.
   */
  const handleResetEvent = () => {
    const cloneProductList = previewProductsList.map((obj) => ({ ...obj }));

    const resetRowData = cloneProductList[currentRow.Sequence - 1];

    //reseting the object
    cloneProductList[currentRow.Sequence - 1] = {};

    const resetNewObj = {
      type: ProductType.BLANK_SPACE,
      isBlankSpace: true,
      Sequence: resetRowData.Sequence,
      PageNumber: resetRowData.PageNumber,
      dimensions: resetRowData.dimensions,
      ProductCardAllocation: resetRowData.ProductCardAllocation
    };
    cloneProductList[currentRow.Sequence - 1] = resetNewObj;

    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: ' Blank space updated successfully',
        type: 'success',
        timeout: 6000,
        typeStatus: 'Success'
      })
    );
    setOpenResetDialog(false);
    setPreviewProductsList(cloneProductList);

    // On success blank space edit Enable "Save Changes" & Disable "Generate Catalogue"
    setIsSaveChangesDisable(false);
    setIsGenerateCatalogueDisabled(true);
  };

  //setting the all columns
  const productColumns = useMemo(() => [
    {
      accessorKey: 'Sequence',
      Header: () => (
        <GridHeaderComponent headerText={'Sequence Number'} />
      ),
      size: 55,
      enableColumnFilter: false
    },
    {
      accessorKey: 'ProductSku',
      header: 'SKU',
      size: 100,
      filterVariant: 'text',
      filterFn: 'contains',
      // filterFn: 'skuFilterFn',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'SKU' }
    },
    {
      accessorKey: 'CatalogueTitle',
      header: 'Product Title',
      // size: 160,
      // filterVariant: 'text',
      // muiTableHeadCellFilterTextFieldProps: { placeholder: 'Title' },
      Cell: ({ renderedCellValue }) => (
        <Box
          minHeight={40}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
          <ReadMore renderedCellValue={renderedCellValue} />
        </Box>
      ),
      enableColumnFilter: false
    },
    {
      accessorKey: 'PageNumber',
      Header: () => (
        <GridHeaderComponent headerText={'Page Number'} />
      ),
      size: 50,
      enableColumnFilter: false
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 30,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div>
          {row.original.isBlankSpace ? (
            row.original.type === ProductType.BLANK_SPACE ? (
              <IconButton sx={{padding: 0}}>
                <EditOutlined
                  onClick={() => {
                    handleOpenEditDialog(row.original);
                  }}
                />
              </IconButton>
            ) : (
              <IconButton sx={{padding: 0}}>
                <RefreshOutlined
                  onClick={() => {
                    handleOpenResetDialog(row.original);
                  }}
                />
              </IconButton>
            )
          ) : (
            ''
          )}
        </div>
      )
    }
  ]);

  const addBlankSpaceObject = (product) => {
    const cloneProductList = previewProductsList.map((obj) => ({ ...obj }));
    cloneProductList[product.Sequence - 1] = product;
    setPreviewProductsList(cloneProductList);

    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: ' Blank space updated successfully',
        type: 'success',
        timeout: 6000,
        typeStatus: 'Success'
      })
    );
  };

  return (
    <Grid container spacing={2} paddingRight={5} paddingTop={2}>
      {loading && <SimpleBackdrop loading={loading} />}
      <Grid
        item
        container
        sx={{
          '.MuiPaper-root': {
            boxShadow: 'none',
            width: '100%'
          },
          '.MuiTableContainer-root': {
            borderRadius: '4px',
            maxHeight: 'clamp(350px, calc(100vh - 200px), 9999px)'
          },
          '.MuiCollapse-root': {
            background: '#ffffff',
            padding: '5px',
            border: '1px solid #e5e5e5',
            borderRadius: '4px',
            marginTop: '10px'
          },
          '.MuiTableCell-root': {
            '&:first-child': {
              textAlign: 'right'
            },
            '&:second-child': {
              textAlign: 'left'
            },
            '&:nth-of-type(4)': {
              textAlign: 'right'
            },
            '&:nth-of-type(5)': {
              textAlign: 'center'
            },
            verticalAlign: 'top',
            padding: '8px'
          },
          '.Mui-TableHeadCell-Content': {
            justifyContent: 'center'
          },
          '.saveChangesBtn': { width: '146px', marginLeft: 'auto', marginTop: '20px' }
        }}>
        <MaterialReactTable
          columns={productColumns}
          data={previewProductsList}
          enableStickyHeader={true}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enablePagination={false}
          enableSorting={false}
          enableFacetedValues
          initialState={{
            showColumnFilters: true
          }}
          muiTableProps={{
            sx: {
              tableLayout: 'fixed'
            }
          }}
          muiTableBodyCellProps={{
            sx: {
              border: '1px solid #e5e5e5',
              fontFamily: 'Brandon Grostesque Regular',
              fontSize: '16px'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              border: '1px solid #e5e5e5',
              background: '#f1f1f1',
              fontFamily: 'Brandon Grostesque Bold',
              fontSize: '16px'
            }
          }}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              backgroundColor: row.original.isBlankSpace
                ? row.original.type === 'BLANK_SPACE'
                  ? BlankSpaceColors.Empty // Highlight empty space
                  : BlankSpaceColors.Filled // Highlight filled space
                : '',
              '&:hover': {
                backgroundColor: ''
              }
            },
            hover: false
          })}
          layoutMode='semantic'
          filterFns={
            {
              // skuFilterFn: (row, _columnIds, filterValue) => {
              //   if (filterValue.length > 2 && row.original.ProductSku) {
              //     return row.original.ProductSku.toLowerCase().startsWith(filterValue.toLowerCase());
              //   }
              // }
            }
          }
        />
        <ButtonComponent
          variant='contained'
          buttonText='Save Changes'
          className='saveChangesBtn'
          disabled={isSaveChangesDisable}
          handleClick={saveChangesAction}
        />
      </Grid>
      <PreviewBlankSpaceDialog
        open={openPreviewBlankSpaceDialog}
        handleClose={handleCloseBlankSpaceDialog}
        setIsSaveChangesDisable={setIsSaveChangesDisable}
        setIsGenerateCatalogueDisabled={setIsGenerateCatalogueDisabled}
        rowData={currentRow}
        productList={previewProductsList}
        addBlankSpaceObject={addBlankSpaceObject}></PreviewBlankSpaceDialog>
      <ResetDialog
        open={openResetDialog}
        handleClose={handleCloseResetDialog}
        rowData={currentRow}
        handleResetEvent={handleResetEvent}></ResetDialog>
    </Grid>
  );
};

export default ProductsStructure;
