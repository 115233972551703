const getUser = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return { refreshToken: null, accessToken: null, email: null, id: null, firstName: null, lastName: null , role: null };
};

const setUser = (payload) => {
  localStorage.setItem('user', JSON.stringify(payload));
};

const removeUser = () => {
  localStorage.removeItem('user');
}

export default { setUser, getUser, removeUser };
