import * as React from 'react';
import ButtonComponent from '../button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogTitle, DialogContent } from '@mui/material';

const InfoDialog = (props) => {
  const { open, handleClose, dialogTitle, dialogContentMessage } = props;

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          fontSize: '16px !important',
          marginTop: '16px',
          padding: '7px 16px',
          minWidth: '96px !important',
          height: '36px !important'
        },
        '.MuiPaper-root': {
          minWidth: '400px'
        }
      }}>
      <div>
        <DialogTitle>
          <Typography variant='h2'>{dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='p' style={{ marginBottom: '6px' }}>
            {dialogContentMessage}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '0 16px 16px 16px' }}>
          <Grid container spacing={1}>
            <Grid item style={{ marginLeft: '5px' }}>
              <ButtonComponent
                variant='contained'
                handleClick={handleClose}
                buttonText='close'
                className='dialog-button'
                btnSize='small'></ButtonComponent>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default InfoDialog;
