import axios from 'axios';
import { config } from '../config/config';

/**
 * Fetch the latest updated timestamp
 * @returns
 */
const getLastUpdatedTimestamp = async () => {
  return (await axios.get(`${config.api_url}/import/updated-timestamp`)).data;
};

/**
 * Fetch the maintenance status
 * @returns
 */
const getMaintenanceStatus = async () => {
  return (await axios.get(`${config.api_url}/import/maintenance`)).data;
};

/**
 * POST API to start maintenance process manually
 * @param {*} postData
 * @returns
 */
const manuallyStartPublishActivity = async (postData) => {
  return (await axios.post(`${config.api_url}/import/pim/publish`, postData, {})).data;
};

export default { getLastUpdatedTimestamp, getMaintenanceStatus, manuallyStartPublishActivity };
