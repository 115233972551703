import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import DropdownComponent from '../../../../components/dropdown';
import theme from '../../../../config/theme';
import { ThemeProvider } from '@emotion/react';
import Typography from '@mui/material/Typography';
import ButtonComponent from '../../../../components/button';
import FilterService from '../../../../services/filters.service';
import { useEffect } from 'react';
import { useState } from 'react';
import BasicTextFields from '../../../../components/textbox';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateFiltersAction } from './filterSection.slice';
import { DirtyCheckAction } from './dirtyCheck.slice';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RegEx, pageSide } from '../preview-catalogue/constants/previewCatalogue.constants';

export default function FilterSection({ handleClick, handleBack }) {
  // Dropdowns Data
  const [season, setSeason] = useState([]);
  const [brand, setBrand] = useState([]);
  const [section, setSection] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [country, setCountry] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [priceLists, setPriceLists] = useState([]);

  // Form Values
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [title, setTitle] = useState('');
  const [startingPage, setStartingPage] = useState('');
  const [showPrice, setShowPrice] = useState(false);
  const [selectedPriceList, setSelectedPriceList] = useState(null);
  const [isStartingPageRight, setIsStartingPageRight] = useState(null);
  const [includeDiscontinuedProducts, setIncludeDiscontinuedProducts] = useState(false);

  // Error Flags
  const [isTitleError, setTitleError] = useState(true);
  const [isNegative, setNegativeError] = useState(false);
  const [showError, setShowError] = useState(false);

  

  const savedFilters = useSelector((state) => state.TemplateFiltersReducer);
  const selectedTemplate = useSelector((state) => state.TemplateReducer);
  const dirtyCheckReducer = useSelector((state) => state.DirtyCheckReducer);

  const dispatch = useDispatch();

  // Get Dropwdown data
  useEffect(() => {
    getSeasonsList();
    getBrandsList();
    getCategoryList();
    getSubCategoryList();
    getRangeList();
    getSectionsList();
    getWarehousesList();
  }, []);

  //Set Data from state
  useEffect(() => {
    setSelectedBrand(savedFilters.selectedBrand);
    setSelectedSeason(savedFilters.selectedSeason);
    setSelectedCategory(savedFilters.selectedCategory);
    setSelectedSubCategory(savedFilters.selectedSubCategory);
    setSelectedRange(savedFilters.selectedRange);
    setSelectedSection(savedFilters.selectedSection);
    setSelectedCountry(savedFilters.selectedCountry);
    setSelectedWarehouse(savedFilters.selectedWarehouse);
    setTitle(savedFilters.title);
    setStartingPage(savedFilters.startingPage);
    setIsStartingPageRight(savedFilters.isStartingPageRight);
    setShowPrice(savedFilters.showPrice);
    setIncludeDiscontinuedProducts(savedFilters.includeDiscontinuedProducts);
    savedFilters.selectedWarehouse ? getCountriesList(savedFilters.selectedWarehouse?.code) : '';

    if (savedFilters.showPrice) {
      getPriceLists();
      setSelectedPriceList(savedFilters.selectedPriceList);
    }
    
    if (
      (!savedFilters.selectedSection &&
      !savedFilters.selectedCategory &&
      !savedFilters.selectedBrand) ||
      !savedFilters.title ||
      !savedFilters.selectedSeason ||
      !savedFilters.selectedCountry ||
      !savedFilters.selectedWarehouse 
    ) {
      setTitleError(true);
      setNegativeError(false);
    } else {
      setTitleError(false);
      setNegativeError(false);
    }
  }, []);

  const handleError = (err) => {
    if (err.response && !err.response.data.statusCode === 401) {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: 'Something went wrong while fetching brands, please try again later.',
          type: 'error',
          timeout: 6000,
          typeStatus: 'Error'
        })
      );
    }
  }

  const getSeasonsList = async () => {
    try {
      let seasons = await FilterService.getSeasons();
      setSeason(seasons);
    } catch (err) {
      handleError(err)
    }
  };

  const getBrandsList = async () => {
    try {
      let brands = await FilterService.getBrands();
      setBrand(brands);
    } catch (err) {
      handleError(err)
    }
  };

  const getSectionsList = async () => {
    try {
      let sections = await FilterService.getSections();
      setSection(sections);
    } catch (err) {
      handleError(err)
    }
  };

  const getCategoryList = async () => {
    try {
      let categories = await FilterService.getCategory();
      setCategory(categories);
    } catch (err) {
      handleError(err)
    }
  };

  const getSubCategoryList = async () => {
    try {
      let subCategories = await FilterService.getSubCategory();
      setSubCategory(subCategories);
    } catch (err) {
      handleError(err);
    }
  };

  const getRangeList = async () => {
    try{
      let ranges = await FilterService.getRanges();
      setRanges(ranges);
    } catch (err) {
      handleError(err);
    }
  }

  const getCountriesList = async (warehouseCode) => {
    try {
      let countries = await FilterService.getCounties(warehouseCode);
      setCountry(countries);
    } catch (err) {
      handleError(err);
    }
  };

  const getWarehousesList = async () => {
    try {
      let warehouses = await FilterService.getWarehouses();
      setWarehouse(warehouses);
    } catch (err) {
      handleError(err);
    }
  };

  const getPriceLists = async () => {
    try {
      let priceLists = await FilterService.getPriceLists();
      setPriceLists(priceLists);
    } catch (err) {
      handleError(err);
    }
  };

  const handleTitle = (event) => {
    setShowError(false);
    let length = event.target.value.trim().length;
    length == 0 ? setTitleError(true) : setTitleError(false);
    setTitle(event.target.value);
    // On change form will be dirty.
    if (!dirtyCheckReducer.isDirty) {
      dispatch(DirtyCheckAction.saveDirtyCheck({ isDirty: true }));
    }
  };

  const handleButtonClick = () => {
    let hasError = false;
    if (!RegEx.PositiveNumber.test(startingPage)) {
      setNegativeError(true);
      setShowError(true);
      hasError = true;
    }

    let pageNumber = parseInt(startingPage, 10);
    if(startingPage.trim().length > 0 && !isNaN(startingPage)){
      if (!(pageNumber >= 1 && pageNumber <= 9999)) {
        setNegativeError(true);
        setShowError(true);
        hasError = true;
      }
    }
    title.trim().length > 40 ? setTitleError(true) : setTitleError(false);
    if (!RegEx.PositiveNumber.test(startingPage) || title.trim().length > 40) {
      setShowError(true);
      hasError = true;
    }
    // Show error if any error found for title and pageNumber
    if (hasError) {
      return;
    }

    // for success.
    setNegativeError(false)
    setShowError(false);
    let values = {
      selectedBrand,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedRange,
      selectedSeason,
      selectedCountry,
      selectedWarehouse,
      title,
      startingPage,
      isStartingPageRight,
      showPrice,
      includeDiscontinuedProducts
    };
    if (showPrice) values["selectedPriceList"] = selectedPriceList;

    dispatch(TemplateFiltersAction.saveFilters(values));
    handleClick();
  };

  const handleBackClick = () => {
    dispatch(TemplateFiltersAction.resetFilterSection());
    handleBack();
  };

  const handleShowPriceEvent = (event) => {
    setShowPrice(event.target.checked);
    setSelectedPriceList(null);
    getPriceLists();
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          padding: '0px 50px 30px 40px',
          '.grid-item': {
            marginRight: '15px !important',
          },
          '@media screen and (max-width: 1220px)': {
            '.grid-item': {
              marginBottom: '40px !important',
            }
          }
        }}
        style={{
          '.MuiGridItem': {
            marginRight: '15px !important'
          }
        }}>
          <Grid style={{ marginLeft: '20px' }}>
            {selectedTemplate.title != null ? (
                <p style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  display: 'inline-block',
                  marginRight: '40px'
                }}>
                  {'Template : '} 
                  <span style={{
                    fontSize: '16px',
                    fontWeight: 'normal'
                  }}>{selectedTemplate.title}</span>
                </p>
              ) : (
                ''
              )}
            </Grid>
           <div style={{ margin: '0px auto 0px 20px' }}>
          <Grid 
            container
            rowSpacing={3}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              margin: 0,
              width: '100%'
            }}>
            <Grid item="true" xs={12}>
              <Typography variant='h2'>Region</Typography>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Warehouse'
                  options={warehouse}
                  handleChange={(event, newValue) => {
                    setSelectedWarehouse(newValue);
                    newValue ? getCountriesList(newValue.code) : '';
                    setSelectedCountry(null);                    
                  }}
                  selectedOption={selectedWarehouse}
                  isRequired={true}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Country'
                  options={country}
                  handleChange={(event, newValue) => {
                    setSelectedCountry(newValue); 
                  }}
                  selectedOption={selectedCountry}
                  disabled={!selectedWarehouse}
                  isRequired={true}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: '50px auto 0px 20px' }}>
          <Grid
            container
            rowSpacing={3}
            sx={{
              display: 'flex',
              margin: 0,
              width: '100%'
            }}>
            <Grid item='true' xs={12}>
              <Typography variant='h2'>
                <span>Filters</span>
                <span
                  style={{
                    fontSize: '16px',
                    marginLeft: '10px',
                    fontStyle: 'italic',
                    color: '#666666'
                  }}>
                  (Any one of Section, Category or Brand must be selected)
                </span>
              </Typography>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Section'
                  options={section}
                  handleChange={(event, newValue) => {
                    setSelectedSection(newValue);                    
                  }}
                  selectedOption={selectedSection}
                  isRequired={false}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Category'
                  options={category}
                  handleChange={(event, newValue) => {
                    setSelectedCategory(newValue);
                  }}
                  selectedOption={selectedCategory}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Brand'
                  options={brand}
                  handleChange={(event, newValue) => {
                    setSelectedBrand(newValue);                    
                  }}
                  selectedOption={selectedBrand}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Subcategory'
                  options={subCategory}
                  handleChange={(event, newValue) => {
                    setSelectedSubCategory(newValue);
                  }}
                  selectedOption={selectedSubCategory}
                />
              </div>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  label='Range'
                  options={ranges}
                  handleChange={(event, newValue) => {
                    setSelectedRange(newValue);                    
                  }}
                  selectedOption={selectedRange}
                  isRequired={false}
                />
              </div>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3} alignContent='center' justifyContent='center' alignSelf='center'>
              <div className='grid-item'>
                <FormControlLabel value="true" control={<Switch color="primary" checked={includeDiscontinuedProducts} />} label="Include Discontinued Products" labelPlacement="end" onChange={() => setIncludeDiscontinuedProducts(event.target.checked)} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: '50px auto 0px 20px' }}>
          <Grid
            container
            rowSpacing={3}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              margin: 0,
              width: '100%'
            }}>
            <Grid item="true" xs={12}>
              <Typography variant='h2'>Catalogue Details</Typography>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <div className='grid-item-margin'>
                  <BasicTextFields
                    isError={isTitleError && showError}
                    errortext='Maximum characters allowed - 40'
                    label='Title'
                    onDataChange={handleTitle}
                    inputValue={title}
                    isRequired={true}
                    placeholder="Title"
                  ></BasicTextFields>
                </div>
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  handleChange={(event, newValue) => {
                    setSelectedSeason(newValue);
                  }}
                  label='Season'
                  options={season}
                  selectedOption={selectedSeason}
                  isRequired={true}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <DropdownComponent
                  handleChange={(event, newValue) => {
                    setIsStartingPageRight(newValue);
                  }}
                  label='Starting Page Side'
                  options={pageSide}
                  selectedOption={isStartingPageRight}
                  isRequired={true}
                />
              </div>
            </Grid>
            <Grid item="true" xs={6} sm={6} md={6} lg={3} sx={{ display: 'flex', }}>
              <div className='grid-item' style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <BasicTextFields
                  label='Starting Page Number'
                  onDataChange={(event) => {
                    parseInt(startingPage, 10);
                    setStartingPage(event.target.value);
                    setNegativeError(false);
                  }}
                  errortext='Accepted values- positive and whole numbers upto 9999'
                  isError={isNegative && showError}
                  inputValue={startingPage}
                  placeholder="Page number"
                >
                </BasicTextFields>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{margin: '24px auto 0px 20px'}}>
        <Grid container rowSpacing={3} style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>
            <Grid item="true" xs={6} sm={12} md={10} lg={6} sx={{ display: 'flex', height: '79px' }}>
            <div className='grid-item' style={{marginTop: '30px'}}>
                <FormControlLabel value="true" control={<Switch color="primary" checked={showPrice} />} label="Show Prices" labelPlacement="end" onChange={handleShowPriceEvent} />
              </div>
              { showPrice === true &&
                <div style={{ width: '50%' }}>
                  <DropdownComponent
                    label='Price List'
                    isRequired={true}
                    options={priceLists}
                    selectedOption={selectedPriceList}
                    handleChange={(event, newValue) => {
                      setSelectedPriceList(newValue);
                    }}
                  />
                </div>
              }
            </Grid>
          </Grid>
        </div>
        <Grid container style={{marginTop: '60px'}}>
        <Grid item="true">
          <ButtonComponent
            variant='outlined'
            buttonText='Go Back'
            handleClick={handleBackClick}></ButtonComponent>
        </Grid>
        <Grid item="true" style={{ marginLeft: 'auto' }}>
            <ButtonComponent
              disabled={
                !(
                  (selectedSection || selectedBrand || selectedCategory) &&
                  selectedSeason &&
                  selectedCountry &&
                  selectedWarehouse &&
                  isStartingPageRight &&
                  !isTitleError &&
                  !isNegative &&
                  (!showPrice || (showPrice && selectedPriceList))
                )
              }
              handleClick={handleButtonClick}
              variant='contained'
              buttonText='Load Products'></ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
