import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ProductType } from '../constants/previewCatalogue.constants';

const ResetDialog = ({ open, handleClose, handleResetEvent, rowData }) => {

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          fontSize: '16px !important',
          padding: '7px 16px',
          minWidth: '96px !important',
          height: '36px !important'
        },
        '.reset-button': {
          padding: '7px 16px',
          backgroundColor: '#ef4242',
          color: '#ffffff !important',
          boxShadow: 'none',
          minWidth: '96px !important',
          height: '36px !important',
          '&:hover': {
            backgroundColor: '#ef4242 !important',
            color: '#ffffff !important',
            opacity: '0.8'
          },
          fontSize: '16px !important'
        },
        '.labelTypography' :{ 
            fontFamily: 'Brandon Grostesque Regular',
            fontSize: '16px',
            marginBottom: '8px',
            marginTop: '4px'
        },
        '.MuiPaper-elevation': {
          maxWidth: '490px !important',
          width: '450px !important'
        },
        '.MuiDialogContent-root': {
          padding: '0 24px 16px 24px'
        }
      }}>
      <DialogTitle>
        <Typography variant='h2'>Reset Blank Space</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <Typography className='labelTypography'>
              Are you sure you want to reset blank space?
            </Typography>
            <Typography>
                <p>This action remove the item added in the blank space.</p>
            </Typography>
            <Typography variant='h3' className='labelTypography'>
              <strong>Blank Space Details</strong>
            </Typography>
            <Typography variant='h4' className='labelTypography'>
              <strong>Option:</strong> {rowData?.type === ProductType.CONTENT_PLACEHOLDER_IMAGE ? 'Image' : ProductType.PRODUCT }
            </Typography>
            <Typography variant='h4' className='labelTypography'>
              <strong>Sequence Number:</strong> {rowData?.Sequence}
            </Typography>
            <Typography variant='h4' className='labelTypography'>
              <strong>Page No.:</strong> {rowData?.PageNumber}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '0 16px 16px 16px'
        }}>
        <Grid container spacing={1}>
          <Grid item sx={{ marginRight: '5px' }}>
            <ButtonComponent
              variant='outlined'
              handleClick={handleClose}
              buttonText='Cancel'
              className='dialog-button'
              btnSize='small'></ButtonComponent>
          </Grid>
          <Grid item xs={3}>
            <ButtonComponent
              variant='contained'
              buttonText='Reset'
              className='reset-button'
              handleClick={handleResetEvent}
              btnSize='small'></ButtonComponent>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ResetDialog;
