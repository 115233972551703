import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Header from '../../../../layouts/header';
import FilterSection from './filterSection';
import SelectCover from '../cover/selectCover';
import SelectProductList from '../select-product/selectProduct';
import SequenceProductList from '../sequence-product/sequenceProduct';
import SelectTemplate from '../select-template/selectTemplate';
import PreviewCatalogue from '../preview-catalogue/previewCatalogue';
import { DirtyCheckAction } from './dirtyCheck.slice';
import { useDispatch, useSelector } from 'react-redux';
import MobileStepperComponent from './components/mobileStepper.jsx';
import StepperComponent from './components/stepper';

import { CoverAction } from '../cover/selectCover.slice';
import { AllProductAction } from '../select-product/allProductList.slice';
import { SelectedProductAction } from '../select-product/selectedProductList.slice';
import { SelectedProductCodeAction } from '../select-product/selectedProductCodeList.slice';
import { TemplateFiltersAction } from '../generate-catalogue/filterSection.slice';
import { TemplateAction } from '../select-template/template.slice';
import { SequenceProductAction } from '../sequence-product/sequenceProducts.slice';
import { PreviewSectionAction } from '../preview-catalogue/previewSection.slice';
import MaintenanceMode from '../maintenance/maintenance-mode.jsx';
import SocketIoMaintenance from '../maintenance/socket-io.maintenance.jsx';
import maintainanceService from '../../../../services/maintainance.service.js';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice.js';


const steps = [
  'Select Template',
  'Select Filters',
  'Select Products',
  'Product Card Allocation',
  'Preview Catalogue',
  'Add Cover Page'
];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isMaintainanceMode, setIsMaintenanceMode] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const smallScreenSize = 900;
  const isSmallScreen = window.innerWidth < smallScreenSize;
  const dispatch = useDispatch();
  const dirtyCheckReducer = useSelector((state) => state.DirtyCheckReducer);

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    // API call to get the flag value.
    maintainanceService.getMaintenanceStatus().then((response) => {
      setIsMaintenanceMode(response.data.maintenanceMode);
    }).catch(err => {
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Couldn\'t get maintainance status , please try again later.',
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    // Show only if form is dirty.
    if (dirtyCheckReducer.isDirty) {
      e.preventDefault();
      e.returnValue = '';
      dispatch(DirtyCheckAction.resetDirtyCheck());
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch(CoverAction.resetCoverDetails());
    dispatch(SelectedProductAction.resetSelectProducts());
    dispatch(SequenceProductAction.resetSequence());
    dispatch(AllProductAction.resetAllProducts());
    dispatch(SelectedProductCodeAction.resetSaveCode());
    dispatch(TemplateFiltersAction.resetFilterSection());
    dispatch(TemplateAction.resetTemplateSection());
    dispatch(PreviewSectionAction.resetPreviewSection());
    dispatch(DirtyCheckAction.resetDirtyCheck());
    setActiveStep(0);
  };

  const handleMaintanenceMode = (maintenanceMode) => {
    setIsMaintenanceMode(maintenanceMode);

    // Reset stepper if maintenanceMode is enabled.
    if (maintenanceMode) {
      handleReset();
    }
  }

  return (
    <>
      <>
        <Header setIsMaintenanceMode={setIsMaintenanceMode} resetStepper={handleReset}></Header>
      </>
      <SocketIoMaintenance handleMaintanenceMode={handleMaintanenceMode} />
      {isMaintainanceMode && <MaintenanceMode />}
      {!isMaintainanceMode && (
        <Box
          sx={{
            '.MuiStepper-root': {
              padding: '30px 50px 20px 50px'
            },
            '.MuiStepIcon-root': {
              color: '#cccccc'
            },
            '.MuiStepLabel-labelContainer': {
              color: '#333333'
            },
            '@media screen and (max-width: 991px)': {
              '.stepper-label': {
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }
            }
          }}>
          {isSmallScreen ? (
            <MobileStepperComponent value={activeStep} stepList={steps} />
          ) : (
            <StepperComponent value={activeStep} stepList={steps} />
          )}
          {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && <SelectTemplate handleClick={handleNext}></SelectTemplate>}
              {activeStep === 1 && (
                <FilterSection handleClick={handleNext} handleBack={handleBack}></FilterSection>
              )}
              {activeStep === 2 && (
                <SelectProductList
                  handleClick={handleNext}
                  handleBack={handleBack}></SelectProductList>
              )}
              {activeStep === 3 && (
                <SequenceProductList
                  handleClick={handleNext}
                  handleBack={handleBack}></SequenceProductList>
              )}
              {activeStep === 4 && (
                <PreviewCatalogue
                  handleClick={handleNext}
                  handleBack={handleBack}></PreviewCatalogue>
              )}
              {activeStep === 5 && <SelectCover handleBack={handleBack}></SelectCover>}
            </React.Fragment>
          )}
        </Box>
      )}
    </>
  );
}
