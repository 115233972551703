import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavigationList } from '../features/admin/navigationList';


const SideNavbar = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const location = useLocation();

  useEffect(() => {
    // Extract the option from the route path
    const currentOption = location.pathname.split('/').pop();
    setSelectedOption(currentOption);
  }, [location]);

  return (
    <Grid style={{ marginTop: 10, borderRight: '1px solid #E5E5E5', height: '100%' }} 
      sx={{
        '@media screen and (max-width:900px)': {
          width:'60px',
          '.MuiListItemText-root': {
            display: 'none'
          }
        }
      }}
    >
      <List>
        {NavigationList.map((option) => (
          <ListItem key={option.key} component={Link} to={option.link} disablePadding>
            <ListItemButton selected={selectedOption === option.key}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText style={{ color: 'black' }} primary={option.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default SideNavbar;
