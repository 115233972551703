import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CopyUrlComponent from '../../components/copyUrl';
import GridHeaderComponent from '../../components/gridHeader';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ButtonComponent from '../../components/button';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '../../components/common/notification/notificationSlice';
import allCatalogueService from '../../services/allCatalogue.service';
import Header from '../../layouts/header';
import { MaterialReactTable } from 'material-react-table';
import SimpleBackdrop from '../../components/loader';
import { useNavigate } from 'react-router-dom';
import DeleteDialog from './dialog/deleteDialog';

import { CoverAction } from '../catalogue/template-first/cover/selectCover.slice';
import { AllProductAction } from '../catalogue/template-first/select-product/allProductList.slice';
import { SelectedProductAction } from '../catalogue/template-first/select-product/selectedProductList.slice';
import { SelectedProductCodeAction } from '../catalogue/template-first/select-product/selectedProductCodeList.slice';
import { TemplateFiltersAction } from '../catalogue/template-first/generate-catalogue/filterSection.slice';
import { TemplateAction } from '../catalogue/template-first/select-template/template.slice';
import { SequenceProductAction } from '../catalogue/template-first/sequence-product/sequenceProducts.slice';
import { PreviewSectionAction } from '../catalogue/template-first/preview-catalogue/previewSection.slice';

import { config } from '../../config/config';
import moment from 'moment/moment';
import 'moment-timezone';
import DownloadFileComponent from '../../components/downloadFile';

const AllCatalogueList = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //set eror
  const [isError, setIsError] = useState(false);

  //used for row count
  const [rowCount, setRowCount] = useState(0);

  //used for no record page
  const [totalRecordCount, setTotalRecordCount] = useState(1);

  //used for column filters
  const [columnFilters, setColumnFilters] = useState([]);

  //used for page index and page count
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const [catalogueList, setcatalogueList] = useState([]);

  //set ID for deleting the catalogue
  const [catalogueId, setCatalogueId] = useState(0);
  //set CatalogueName for deleting the catalogue
  const [catalogueTitle, setCatalogueTitle] = useState('');

  const [deleteOpenDialog, setOpenDeleteDialog] = React.useState(false);
  const dispatch = useDispatch();
  let brandListDD = [];
  let sectionListDD = [];
  let seasonListDD = [];

  // Clearing the state from redux.
  dispatch(CoverAction.resetCoverDetails());
  dispatch(SelectedProductAction.resetSelectProducts());
  dispatch(SequenceProductAction.resetSequence());
  dispatch(AllProductAction.resetAllProducts());
  dispatch(SelectedProductCodeAction.resetSaveCode());
  dispatch(TemplateFiltersAction.resetFilterSection());
  dispatch(TemplateAction.resetTemplateSection());
  dispatch(PreviewSectionAction.resetPreviewSection())

  // For table reference
  const tableRef = React.createRef();

  //should be memoized or stable
  useEffect(() => {
    refreshList();
    // scroll the table container to top
    tableRef.current.refs.tableContainerRef.current.scrollTo(0, 0)
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  // const onHandleClick = () => {
  //     setOpenDeleteDialog(true);
  // };

  const handleClose = () => {
    setCatalogueId(0);
    setOpenDeleteDialog(false);
    setCatalogueTitle('');
  };

  const handleError = (err) => {
    if (err.response && !err.response.data.statusCode === 401) {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: 'Something went wrong while fetching brands, please try again later.',
          type: 'error',
          timeout: 6000,
          typeStatus: 'Error'
        })
      );
    }
  }

  const getCatalogueList = async () => {
    setLoading(true);
    let brand = '';
    let section = '';
    let season = '';
    let title = '';
    columnFilters.forEach((filter) => {
      if (filter.id == 'title') {
        title = encodeURIComponent(filter.value);
      }
      if (filter.id == 'season') {
        season = encodeURIComponent(filter.value);
      } else if (filter.id == 'section') {
        section = encodeURIComponent(filter.value);
      } else if (filter.id == 'brand') {
        brand = encodeURIComponent(filter.value);
      }
    });

    try {
      let cataloguefilters =
        'pageSize=' +
        pagination.pageSize +
        '&pageIndex=' +
        pagination.pageIndex +
        '&brand=' +
        brand +
        '&season=' +
        season +
        '&section=' +
        section +
        '&title=' +
        title;
      let catalogueListResponse = await allCatalogueService.getAllCatalogue(cataloguefilters);
      setcatalogueList(catalogueListResponse.results.map((item) => {
        return {
          ...item,
          isDownloading72: false,
          isDownloading300: false
        }
      }));
      setRowCount(catalogueListResponse.total);
      setTotalRecordCount(catalogueListResponse.totalCount);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      handleError(err);
    }
  };

  const getBrandList = async () => {
    try {
      let brandList = await allCatalogueService.getBrand();
      if (brandListDD.length == 0) {
        brandList
          .map((item) => item.brand)
          .forEach((item) => {
            brandListDD.push(item);
          });
      }
    } catch (err) {
      handleError(err)
    }
  };

  const getSectionList = async () => {
    try {
      let sectionList = await allCatalogueService.getSection();
      if (sectionListDD.length == 0) {
        sectionList
          .map((item) => item.section)
          .forEach((item) => {
            sectionListDD.push(item);
          });
      }
    } catch (err) {
      handleError(err);
    }
  };


  const getSeasonList = async () => {
    try {
      let seasonList = await allCatalogueService.getSeason();
      if (seasonListDD.length == 0) {
        seasonList
          .map((item) => item.season)
          .forEach((item) => {
            seasonListDD.push(item);
          });
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleDeleteCatalogue = async () => {
    let deleteResponse = await allCatalogueService.deleteCatalogueById(catalogueId);
    if (deleteResponse.data.success === true) {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: 'Catalogue deleted successfully',
          type: 'success',
          timeout: 6000,
          typeStatus: 'Success'
        })
      );
    } else {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: 'Something went wrong while deleting the catalogue, plerase try again later',
          type: 'error',
          timeout: 6000,
          typeStatus: 'Error'
        })
      );
    }
    handleClose();
    refreshList();
  };

  function deleteCatalogue(catId, catTitle) {
    setCatalogueId(catId);
    setCatalogueTitle(catTitle);
    setOpenDeleteDialog(true);
  }

  async function refreshList() {
    await getBrandList();
    await getSeasonList();
    await getSectionList();
    await getCatalogueList();
  }

  const catalogueColumns = useMemo(
    () => [
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Title',
        size: 200,
        filterVariant: 'text',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Title' },
      },
      {
        accessorKey: 'user',
        header: 'Generated By',
        size: 150,
        enableColumnFilter: false,
        Cell: (props) => (
          <>
            {props.row.original.user?.firstName} {props.row.original.user?.lastName}
          </>
        )
      },
      {
        accessorKey: 'createdAt', //normal accessorKey
        // header: 'Generated Date & Time',
        Header: () => (
          <GridHeaderComponent headerText={'Generated Date & Time '} headerTextLine2={'DD-MM-YYYY HH:mm'} />
        ),
        size: 168,
        enableColumnFilter: false,
        Cell: (props) => (
          moment(props.row.original.createdAt).tz(config.timeZone).format('DD-MM-YYYY HH:mm')
        )
      },
      {
        accessorKey: 'section',
        header: 'Section',
        size: 150,
        enableFacetedValues: 'true',
        filterVariant: 'select',
        filterSelectOptions: sectionListDD,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Section' },
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
        size: 150,
        enableFacetedValues: 'true',
        filterVariant: 'select',
        filterSelectOptions: brandListDD,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Brand' },
      },
      {
        accessorKey: 'season',
        header: 'Season',
        size: 150,
        enableFacetedValues: 'true',
        filterVariant: 'select',
        filterSelectOptions: seasonListDD,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Season' },
      },
      {
        accessorKey: 'country',
        header: 'Country',
        size: 100,
        enableColumnFilter: false,
      },
      {
        // accessorKey: 'pdfUrlWeb',
        id: 'copyUrl',
        accessorFn: (row) => row.pdfUrlWeb,
        header: 'Copy URL',
        enableColumnFilter: false,
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <CopyUrlComponent renderedCellValue={renderedCellValue} />
        )
      },
      {
        accessorKey: 'pdfUrlWeb',
        // header: 'PDF 72 dpi',
        Header: () => (
          <GridHeaderComponent headerText={'PDF 72 dpi'} />
        ),
        enableColumnFilter: false,
        minSize: 30,
        maxSize: 40,
        Cell: ({ renderedCellValue, row }) => (
          <DownloadFileComponent
            record={row.original}
            downloadUrl={renderedCellValue}
            updateListMethod={setcatalogueList}
            downloadColumnName={'isDownloading72'}
          />
        )
      },
      {
        accessorKey: 'pdfUrlPrint',
        // header: 'PDF 300 dpi',
        Header: () => (
          <GridHeaderComponent headerText={'PDF 300 dpi'} maxWidth='56px' />
        ),
        enableColumnFilter: false,
        minSize: 50,
        maxSize: 55,
        Cell: ({ renderedCellValue, row }) => (
          <DownloadFileComponent
            record={row.original}
            downloadUrl={renderedCellValue}
            updateListMethod={setcatalogueList}
            downloadColumnName={'isDownloading300'}
          />
        )
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        enableColumnFilter: false,
        size: 50,
        Cell: ({ row }) => (
          <IconButton
            color='error'
            onClick={() => {
              deleteCatalogue(row.id, row.original.title);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )
      }
    ],
    []
  );

  return (
    <div>
      <Header></Header>
      {loading && <SimpleBackdrop loading={loading} />}
      {totalRecordCount !== 0 ? (
        <Box sx={{ padding: '30px' }}>
          <Box
            sx={{
              '.MuiPaper-root': {
                boxShadow: 'none'
              },
              '.MuiTableContainer-root': {
                borderRadius: '4px',
                maxHeight: '64vh'
              },
              '.MuiCollapse-root': {
                background: '#ffffff',
                padding: '5px',
                border: '1px solid #e5e5e5',
                borderRadius: '4px',
                marginTop: '10px'
              },
              '.MuiTableCell-root': {
                '&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10), &:nth-of-type(11)':
                {
                  textAlign: 'center'
                },
                verticalAlign: 'top',
                padding: '8px'
              },
              '.Mui-TableHeadCell-Content': {
                justifyContent: 'center'
              },
              '@media screen and (max-width: 1120px)': {
                'MuiTableContainer-root': {
                  overflowX: 'scroll'
                }
              },
              '.MuiButtonBase-root': {
                margin: '0px 0px 5px 2px'
              },
              '.Mui-TableHeadCell-Content-Wrapper': {
                minWidth: '0ch'
              },
            }}
          >
            <Typography variant='h2' style={{ marginBottom: '26px' }}>
              All Catalogues
            </Typography>
            <MaterialReactTable
              tableInstanceRef={tableRef}
              columns={catalogueColumns}
              data={catalogueList}
              selectAllMode='all'
              enableStickyHeader={true}
              enableColumnActions={false}
              enableSorting={false}
              enableTopToolbar={false}
              enableFacetedValues
              initialState={{
                showColumnFilters: true
              }}
              manualFiltering
              manualPagination
              muiTableBodyCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  fontFamily: 'Brandon Grostesque Regular',
                  fontSize: '16px'
                }
              }}
              muiTableHeadCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  background: '#f1f1f1',
                  fontFamily: 'Brandon Grostesque Bold',
                  fontSize: '16px'
                }
              }}
              onColumnFiltersChange={setColumnFilters}
              onPaginationChange={setPagination}
              rowCount={rowCount}
              state={{
                columnFilters,
                pagination,
                showAlertBanner: isError
              }}
              getRowId={(originalRow) => originalRow.id}
            />
          </Box>
          <DeleteDialog
            open={deleteOpenDialog}
            handleClose={handleClose}
            handleDelete={handleDeleteCatalogue}
            catalogueName={catalogueTitle}
          />
        </Box>
      ) : (
        <Box
          sx={{
            '.MuiButtonBase-root': {
              width: 'auto'
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '500px'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Typography variant='h1' style={{ marginBottom: '60px' }}>
                You have not generated any catalogue yet!
              </Typography>
              <ButtonComponent
                variant='contained'
                buttonText='Generate Catalogue'
                handleClick={() => navigate('/generate')}
              ></ButtonComponent>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default AllCatalogueList;
