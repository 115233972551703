import axios from 'axios';
import { config } from '../config/config';

const getMarkets = () => {
  return axios.get(`${config.api_url}/market`).then((res) => {
    return res.data;
  });
};

const getSeasons = () => {
  return axios.get(`${config.api_url}/season`).then((res) => {
    return res.data;
  });
};

const getBrands = () => {
  return axios.get(`${config.api_url}/filters/brands`).then((res) => {
    return res.data;
  });
};

const getSections = () => {
  return axios.get(`${config.api_url}/filters/sections`).then((res) => {
    return res.data;
  });
};

const getCategory = () => {
  return axios.get(`${config.api_url}/filters/categories`).then((res) => {
    return res.data;
  });
};

const getSubCategory = () => {
  return axios.get(`${config.api_url}/filters/sub-categories`).then((res) => {
    return res.data;
  });
};

const getRanges = () => {
  return axios.get(`${config.api_url}/filters/ranges`).then((res) => {
    return res.data;
  });
};

const getCounties = (warehouseCode) => {
  return axios
    .get(`${config.api_url}/filters/countries`, { params: { warehouseCode } })
    .then((res) => {
      return res.data;
    });
};

const getWarehouses = () => {
  return axios.get(`${config.api_url}/filters/warehouses`).then((res) => {
    return res.data;
  });
};

const getPriceLists = () => {
  return axios.get(`${config.api_url}/price-lists`).then((res) => {
    return res.data;
  });
};

export default {
  getSeasons,
  getMarkets,
  getBrands,
  getCategory,
  getSections,
  getSubCategory,
  getRanges,
  getCounties,
  getWarehouses,
  getPriceLists
};
