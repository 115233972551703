import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

const UpdateDataDialog = (props) => {
  const { open, handleClose, handleUpdateData } = props;
  const [isClicked, setIsClicked] = useState(false);

  const handleUpdateDataWrapper = () => {
    setIsClicked(true);
    handleUpdateData();
  };

  useEffect(() => {
    if (!open) {
      setIsClicked(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          fontSize: '16px !important',
          padding: '7px 16px',
          minWidth: '96px !important',
          height: '36px !important'
        },
        '.update-button': {
          padding: '7px 16px',
          backgroundColor: '#ef4242',
          color: '#ffffff !important',
          boxShadow: 'none',
          minWidth: '96px !important',
          height: '36px !important',
          '&:hover': {
            backgroundColor: '#ef4242 !important',
            color: '#ffffff !important',
            opacity: '0.8'
          },
          fontSize: '16px !important'
        },
        '.MuiPaper-elevation': {
          maxWidth: '778px !important',
          width: '778px !important'
        },
        '.MuiDialogContent-root': {
          padding: '0 24px 16px 24px'
        }
      }}>
      <DialogTitle>
        <Typography variant='h2'>Update Data Now</Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <p
            style={{
              fontFamily: 'Brandon Grostesque Regular',
              fontSize: '16px',
              marginBottom: '8px',
              marginTop: '4px',
              color: '#333333'
            }}>
            Are you sure you want to update data?
          </p>
          <p
            style={{
              fontFamily: 'Brandon Grostesque Regular',
              fontSize: '16px',
              color: '#666666',
              marginBottom: '6px',
              marginTop: '4px'
            }}>
            This action will take awhile, and users will not be able to generate catalogue, while
            product data is being updated.
          </p>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '0 16px 16px 16px'
        }}>
        <Grid container spacing={1}>
          <Grid item sx={{ marginRight: '5px' }}>
            <ButtonComponent
              variant='outlined'
              handleClick={handleClose}
              buttonText='Cancel'
              className='dialog-button'
              btnSize='small'></ButtonComponent>
          </Grid>
          <Grid item xs={3}>
            <ButtonComponent
              variant='contained'
              buttonText='Update'
              className='update-button'
              disabled={isClicked}
              handleClick={handleUpdateDataWrapper}
              btnSize='small'></ButtonComponent>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDataDialog;
