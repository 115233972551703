import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../services/localstorage.service';
import { config } from '../config/config';

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const tokenUrl = `${config.api_url}/auth/token`;

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    setIsSet(true);

    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        const token = localStorageService.getUser().accessToken;
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;

        if (error && error.request && error.request.status == 0) {
          // To check if request is made but response not received from server
          localStorageService.removeUser();
          navigate('/login');
        }

        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          originalRequest.url === tokenUrl
        ) {
          // To check if refresh token request is made
          localStorageService.removeUser();
          navigate('/login');
        }

        if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const { refreshToken, email } = localStorageService.getUser();
          if (!email || !refreshToken) {
            navigate('/login');
            return;
          }
          const res = await axios.post(tokenUrl, {
            email,
            refreshToken
          });
          if (res.status === 200) {
            localStorageService.setUser({
              ...res.data,
              email: localStorageService.getUser().email
            });
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + localStorageService.getUser().accessToken;
            return axios(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject();
      axios.interceptors.request.eject();
    };
  }, []);

  return isSet && children;
};

export { AxiosInterceptor };
