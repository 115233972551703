import * as React from 'react';
import { NotificationActions } from './common/notification/notificationSlice';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

// CopyUrlComponent Component
const CopyUrlComponent = ({ renderedCellValue }) => {

  const dispatch = useDispatch();

  /**
   * Add temporary text area on document, load value in it, copy it and remove at the end.
   * 
   * @param {Event} event 
   * @param {string} renderedCellValue 
   */
  function copyURL(event, renderedCellValue) {
    console.log('renderedCellValue', renderedCellValue);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(renderedCellValue);
    } else {
      // Fallback for browsers that do not support Clipboard API
      const textArea = document.createElement('textarea');
      textArea.value = renderedCellValue;
      textArea.style.position = 'absolute';
      textArea.style.left = '-1000px';
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: 'Link copied successfully',
        type: 'success',
        timeout: 6000,
        typeStatus: 'Success'
      })
    );
  }

  return (
    <IconButton
      color='primary'
      onClick={(e) => {
        copyURL(e, renderedCellValue);
      }}
    >
      <ContentCopyOutlinedIcon style={{ color: '#333333' }} />
    </IconButton>
  );
};

export default CopyUrlComponent;
