import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import { config } from '../../../../config/config';

const socket = io(config.socketBaseUrl, {
  path: config.socketPath,
  autoConnect: false,
  secure: true
});

export default function SocketIoMaintenance({ handleMaintanenceMode = null, handleFailureNotificationDisplay = null }) {
  // Maintenance channel message handler.
  const onMaintenanceEventHandler = (response) => {
    console.log('Socket message arrived to ' + config.maintenanceChannelName, response);

    // maintenanceMode flag handler
    if (
      Object.prototype.hasOwnProperty.call(response, 'maintenanceMode') &&
      typeof handleMaintanenceMode === 'function'
    ) {
      handleMaintanenceMode(response.maintenanceMode);
    }

    //  dataImportFailure flag handler
    if (
      Object.prototype.hasOwnProperty.call(response, 'dataImportFailure') &&
      typeof handleFailureNotificationDisplay === 'function'
    ) {
      handleFailureNotificationDisplay(response.dataImportFailure);
    }

    return response;
  };

  useEffect(() => {
    socket.connect();
    socket.on(config.maintenanceChannelName, onMaintenanceEventHandler);
    socket.on('connect_error', (error) => console.error('SocketIo connection error:', error));

    return () => {
      socket.disconnect();
      socket.on(config.maintenanceChannelName, onMaintenanceEventHandler);
    };
  }, []);
  return <></>;
}
