import { createTheme } from '@mui/material/styles';
// A custom theme for this app
const theme = createTheme({
  palette: {
    common: {
      black: '#333333',
      white: '#ffffff'
    },
    primary: {
      main: '#ffc845',
      light: '#ffe099',
      contrastText: '#333333'
    },
    secondary: {
      main: '#333333',
      contrastText: '#333333'
    },
    error: {
      main: '#ef4242',
      light: '#fef2f2',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#dd4e00',
      light: '#fff7ed',
      contrastText: '#ffffff'
    },
    info: {
      main: '#0284c7',
      light: '#f0f9ff',
      contrastText: '#ffffff'
    },
    success: {
      main: '#5cb8b2',
      light: '#edf7f7',
      contrastText: '#ffffff'
    },
    grey: {
      400: '#f2f2f2',
      500: '#f1f1f1',
      600: '#e5e5e5',
      700: '#cccccc',
      800: '#666666'
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Brandon Grostesque Regular',
    h1: {
      fontFamily: 'Brandon Grostesque Bold',
      fontSize: '30px'
    },
    h2: {
      fontFamily: 'Brandon Grostesque Bold',
      fontSize: '20px',
      color: '#333333'
    },
    h5: {
      fontFamily: 'Brandon Grostesque Bold',
      fontSize: '18px',
      color: '#333333'
    },
    p: {
      fontSize: '16px',
      color: '#333333'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            color: '#333333 !important',
            boxShadow: 'none',
            whiteSpace: 'nowrap',
            borderRadius: '0',
            padding: '14px 16px',
            minWidth: '160px',
            borderBottom: '3px solid #ffffff',
            '&:hover': {
              boxShadow: 'none',
              borderBottom: '3px solid #ffffff',
              color: '#ffc845',
              backgroundColor: '#ffffff'
            },
            '&:active': {
              borderBottom: '3px solid #ffc845',
              borderRadius: '0'
            }
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            boxShadow: 'none',
            borderRadius: '50px',
            backgroundColor: '#ffc845',
            color: '#333333 !important',
            padding: '8px 16px',
            minWidth: '160px',
            height: '46px !important',
            // borderColor: '#ffc845',
            '&:hover': {
              color: '#333333 !important',
              boxShadow: 'none',
              backgroundColor: '#ffe099 !important',
              //border: '3px solid #ffe099'
            },
            '&:disabled': {
              color: '#666666 !important',
              backgroundColor: '#cccccc !important',
              //border: '3px solid #CCCCCC'
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#333333 !important',
            boxShadow: 'none',
            borderRadius: '50px',
            border: '3px solid #ffc845',
            backgroundColor: '#ffffff !important',
            padding: '8px 16px',
            minWidth: '160px',
            height: '46px !important',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#fff7e5 !important',
              border: '3px solid #ffc845 !important'
            },
            '&:disabled': {
              backgroundColor: '#ffffff',
              borderColor: '#cccccc !important',
              border: '3px solid #cccccc',
              color: '#666666 !important'
            }
          }
        }
      ]
    },
    MuiStepper: {
      style: {
        'MuiSvgIcon-root-MuiStepIcon-root': {
          color: '#ccccccc'
        }
      }
    }
  }
});

export default theme;
