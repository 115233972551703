import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Dropdown Component
const DropdownComponent = ({ options, label, handleChange, selectedOption, isRequired, disabled }) => {
  return (
    <Autocomplete
      value={selectedOption}
      options={options ? options : []}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      disabled={disabled}
      sx={{
        fontFamily: 'Brandon Grostesque Bold',
        fontSize: '14px',
        color: '#333333',
        '.Mui-disabled' : {
          background: '#f2f2f2',
          cursor: 'not-allowed'
        }
      }}
      renderInput={(params) => (
        <div>
          <div style={{ margin: '3px 0px' }}>
            <label>{label} {isRequired ? <span style={{color: '#ef4242'}}> *</span> : ""}</label>
          </div>
          <TextField {...params} label=''placeholder="Select" />
        </div>
      )}
    />
  );
};

// const options = [
//   { label: 'Option 1', value: 1 },
//   { label: 'Option 2', value: 2 },
//   { label: 'Option 3', value: 3 }
//   // Add more options as needed
// ];

export default DropdownComponent;
