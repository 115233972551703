import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogTitle, DialogContent } from '@mui/material';


const StatusDialog = (props) => {
  const { open, closeStatusDialog, statusChangeDialog, UserName, isActivate } = props;

  return (
    <>
        <Dialog
          open={open}
          sx={{
              '.dialog-button': {
                  fontSize: '16px !important',
                //   marginTop: '16px',
                  padding: '7px',
                  minWidth: '96px !important',
                  height: '36px !important',
              },
              '.dialog-success-button': {
                fontSize: '16px !important',
                // marginTop: '16px',
                padding: '7px',
                minWidth: '96px !important',
                height: '36px !important',
                backgroundColor: '#5CB8B2',
                color: 'white !important',
                '&:hover': {
                    backgroundColor: '#5CB8B2 !important',
                    color: '#ffffff !important',
                  opacity: '0.8'
                }
              },
              '.dialog-delete-button': {
                fontSize: '16px !important',
                // marginTop: '16px',
                backgroundColor: '#ef4242',
                color: '#ffffff !important',
                boxShadow: 'none',
                minWidth: '96px !important',
                height: '36px !important',
                '&:hover': {
                  backgroundColor: '#ef4242 !important',
                  color: '#ffffff !important',
                  opacity: '0.8'
                },
              },
              '.MuiPaper-root': {
                  minWidth: '400px'
              }
          }}
        >
            <DialogTitle>
                <Typography variant='h2'>
                { !isActivate ? "Deactivate" : "Activate" } User
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant='p' style={{ marginBottom: '6px' }}>
                    Are you sure you want to deactivate <b> {UserName} </b> ?
                    { !isActivate ?  <p>This will disable the user access to this application.</p> :
                    <p>This will enable the user access to this application with the their role assigned.</p> }
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '0 16px 16px 16px' }}>
              <Grid container spacing={1}>
                <Grid item style={{ marginLeft: '5px' }}>
                    <ButtonComponent
                        variant='outlined'
                        handleClick={closeStatusDialog}
                        buttonText='cancel'
                        className='dialog-button'
                        btnSize="small"
                    ></ButtonComponent>
                </Grid>
                <Grid item xs={3}>
                    <ButtonComponent
                        variant='contained'
                        handleClick={statusChangeDialog}
                        buttonText= { isActivate ? 'Activate' : 'Deactivate' }
                        className= { !isActivate ? 'dialog-delete-button' : 'dialog-success-button'} 
                        btnSize="small"
                    ></ButtonComponent>
                  </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    </>
  );
};

export default StatusDialog;
