import { createSlice } from '@reduxjs/toolkit';

export const selectedProductInitialState = [];

export const SelectedProductSlice = createSlice({
  name: 'selectedproduct',
  initialState: selectedProductInitialState,
  reducers: {
    saveSelectedProducts: (_state, action) => ({
      ...selectedProductInitialState,
      ...action.payload
    }),
    resetSelectProducts: () => ({
      ...selectedProductInitialState
    })
  }
});

export const SelectedProductAction = SelectedProductSlice.actions;
export const SelectedProductReducer = SelectedProductSlice.reducer;
