import { useState } from 'react';
import SocketIoMaintenance from '../features/catalogue/template-first/maintenance/socket-io.maintenance';
import localstorageService from '../services/localstorage.service';
import { UserRoles } from '../features/admin/users/enums/userRole';
import InfoDialog from '../components/dialogs/infoDialog';
import { config } from '../config/config';

export default function DataImportFailureDialog() {

  const [showFailureNotification, setShowFailureNotification] = useState(false);

  const handleFailureNotificationDisplay = (dataImportFailure) => {

    setShowFailureNotification(false);
    const currentUser = localstorageService.getUser();
    const adminRolesList = [UserRoles.ADMIN.toUpperCase(), UserRoles.SUPERADMIN.toUpperCase()];

    // display failure notification if user is admin or superadmin.
    if (adminRolesList.includes(currentUser.role.toUpperCase())) {
      setShowFailureNotification(dataImportFailure);
    }
  }

  const handleDataImportFailureDialogueClose = () => {
    setShowFailureNotification(false);
  }

  return (
    <>
      <SocketIoMaintenance handleFailureNotificationDisplay={handleFailureNotificationDisplay} />
      <InfoDialog
        open={showFailureNotification}
        handleClose={handleDataImportFailureDialogueClose}
        dialogTitle={(<>
          <span>Data Import Failure</span>
        </>)}
        dialogContentMessage={(<>
          There was an issue with the data updating process from the channel - <b>{config.pimChannelName}.</b>
          <p>Please try to update data manually by performing following steps,
            <ol>
              <li>
                Click on the user name (last menu option with your initials)
              </li>
              <li>
                Click on the button <i>Update Data Now</i>
              </li>
            </ol>
          </p>
        </>)} />
    </>
  );
};