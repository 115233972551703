import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NotificationActions } from './notificationSlice';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { CheckCircleOutline } from '@mui/icons-material';

export const Notification = () => {
  const notifications = useSelector(
    (NotificationReducer) => NotificationReducer.NotificationReducer
  );
  const dispatch = useDispatch();
  const handleClose = (_, reason) =>
    reason !== 'clickaway' && dispatch(NotificationActions.clearNotification());

  return (
    <Snackbar
      open={notifications.open}
      autoHideDuration={notifications.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert
        icon={notifications.type == "error" ? <CancelOutlinedIcon fontSize="inherit" /> : <CheckCircleOutline fontSize="inherit" />}
        variant='filled'
        onClose={handleClose}
        severity={notifications.type}
        sx={{ color: '#ffffff' }}>
        <p style={{ fontFamily: 'Brandon Grostesque Bold', margin: '0px 0px 8px 0px' }}>
          {notifications.typeStatus}
        </p>
        {notifications.message}
      </Alert>
    </Snackbar>
  );
};
