import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import theme from '../../../../config/theme';
import Img from '../../../../assets/images/Maintenance.svg';
import { Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import ButtonComponent from '../../../../components/button';
export default function MaintenanceMode() {
  let navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          padding: '100px 50px 30px 50px',
          '.Mui-disabled': {
            background: '#f2f2f2'
          },
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          src={Img}
          style={{
            marginTop: '130px',
            marginBottom: '30px',
            height: '110px',
            width: '110px'
          }}></img>
        <Typography
          variant='h1'
          style={{
            fontFamily: 'Brandon Grostesque Regular',
            fontSize: '30px',
            fontWeight: 'bold',
            margintop: '553px',
            marginBottom: '25px',
            height: '43px',
            color: '#333333',
            opacity: '1',
            letterSpacing: '0.6px'
          }}>
          We are under maintenance.
        </Typography>
        <span
          style={{
            fontFamily: 'Brandon Grostesque Regular',
            fontSize: '18px',
            margintop: '621px',
            height: '26px',
            color: '#333333',
            opacity: '1',
            letterSpacing: '0.36px'
          }}>
          We are currently upgrading our system with the latest data. Thanks for your patience.
        </span>
        <Grid container style={{ marginTop: '60px', marginRight: 'auto', marginLeft: 'auto' }}>
          <Grid item>
            <ButtonComponent
              variant='contained'
              buttonText='All Catalogues'
              handleClick={() => navigate('/allCatalogues')}></ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
