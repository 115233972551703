import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import R2C1 from '../../../../../assets/images/product-card/R2C1.svg';
import R2C2 from '../../../../../assets/images/product-card/R2C2.svg';
import R2C3 from '../../../../../assets/images/product-card/R2C3.svg';
import R3C1 from '../../../../../assets/images/product-card/R3C1.svg';
import R3C2 from '../../../../../assets/images/product-card/R3C2.svg';
import R3C3 from '../../../../../assets/images/product-card/R3C3.svg';
import R3C4 from '../../../../../assets/images/product-card/R3C4.svg';
import R3C5 from '../../../../../assets/images/product-card/R3C5.svg';
import R3C6 from '../../../../../assets/images/product-card/R3C6.svg';
import R4C1 from '../../../../../assets/images/product-card/R4C1.svg';
import R4C2 from '../../../../../assets/images/product-card/R4C2.svg';
import R4C3 from '../../../../../assets/images/product-card/R4C3.svg';
import R4C4 from '../../../../../assets/images/product-card/R4C4.svg';
import R4C5 from '../../../../../assets/images/product-card/R4C5.svg';
import { Box } from '@mui/material';

const ProductCardInfoDialog = (props) => {
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '1300px',
          height: '100%'
        },
        '.dialog-button': {
          fontSize: '16px !important',
          padding: '7px',
          minWidth: '96px !important',
          height: '36px !important'
        }
      }}>
      <DialogTitle>
        <Typography variant='h2'>Product Cards</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R2C1} height='362px' width='593px'></img>
            </Box>
            <Box>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R2C1 - Half page, full width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R2C2} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R2C2 - Half page, half width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R2C3} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R2C3 - Half page, third width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C1} height='362px' width='585px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C1 - Third page, full width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C2} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C2 - Third page, half width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C3} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C3 - Third page, third width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C4} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C4 - Third page, quarter width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C5} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C5 - Third page, fifth width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R3C6} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R3C6 - Third page, sixth width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R4C1} height='362px' width='585px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R4C1 - Quarter page, full width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R4C2} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R4C2 - Quarter page, half width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R4C3} height='362px' width='585px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R4C3 - Quarter page, third width
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R4C4} height='362px' width='593px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R4C4 - Quarter page, quarter width
              </p>
            </Box>
          </Grid>
          <Grid xl={6} lg={6} md={12} sm={12}>
            <Box
              sx={{ border: '1px solid rgba(230, 227, 227)', padding: '5px', textAlign: 'center' }}>
              <img src={R4C5} height='362px' width='585px'></img>
            </Box>
            <Box sx={{ height: '40px' }}>
              <p style={{ margin: '0', marginTop: '5px', textAlign: 'center', fontSize: '15px' }}>
                R4C5 - Quarter page, fifth width
              </p>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '0 16px 16px 16px'
        }}>
        <div>
          <Grid container marginTop={2}>
            <Grid item style={{ marginLeft: 'auto' }}>
              <ButtonComponent
                variant='contained'
                handleClick={handleClose}
                buttonText='Close'
                className='dialog-button'
                btnSize='small'></ButtonComponent>
            </Grid>
          </Grid>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ProductCardInfoDialog;
