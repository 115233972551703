import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

const DeleteDialog = (props) => {
  const { open, handleClose, catalogueName, handleDelete } = props;

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          fontSize: '16px !important',
          padding: '7px 16px',
          minWidth: '96px !important',
          height: '36px !important',
        },
        '.delete-button': {
          padding: '7px 16px',
          backgroundColor: '#ef4242',
          color: '#ffffff !important',
          boxShadow: 'none',
          minWidth: '96px !important',
          height: '36px !important',
          '&:hover': {
            backgroundColor: '#ef4242 !important',
            color: '#ffffff !important',
            opacity: '0.8'
          },
          fontSize: '16px !important'
        },
        '.MuiPaper-elevation': {
          maxWidth: '490px !important',
          width: '450px !important'
        },
        '.MuiDialogContent-root' : {
          padding: '0 24px 16px 24px'
        }
      }}>
      <DialogTitle>
        <Typography variant='h2'>
          Delete Catalogue
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <p
              style={{
                fontFamily: 'Brandon Grostesque Regular',
                fontSize: '16px',
                marginBottom: '8px',
                marginTop: '4px'
              }}>
              Are you sure you want to delete catalogue
              <span style={{ fontFamily: 'Brandon Grostesque Bold' }}> {catalogueName}?</span>
            </p>
            <p
              style={{
                fontFamily: 'Brandon Grostesque Regular',
                fontSize: '16px',
                color: '#666666',
                marginBottom: '6px',
                marginTop: '4px'
              }}>
              This action cannot be reversed.
            </p>
        </div>
      </DialogContent>
      <DialogActions sx={{
        padding: '0 16px 16px 16px'
      }}>
        <Grid container spacing={1}>
          <Grid item sx={{ marginRight: '5px' }}>
            <ButtonComponent
              variant='outlined'
              handleClick={handleClose}
              buttonText='Cancel'
              className='dialog-button'
              btnSize="small"
              ></ButtonComponent>
          </Grid>
          <Grid item xs={3}>
            <ButtonComponent
              variant='contained'
              buttonText='Delete'
              className='delete-button'
              handleClick={handleDelete}
              btnSize="small"
              ></ButtonComponent>
          </Grid>
        </Grid>
        </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
