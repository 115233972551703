import React from 'react';

const Seasons = () => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <h2>Season Content</h2>
          <p>This is the content for Seasons.</p>
        </div>
      </div>
    </>
  );
};
export default Seasons;
