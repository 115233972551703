import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import { DialogTitle, DialogContent, Box } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fileupload from '../../../../../components/fileUpload';
import BasicTextFields from '../../../../../components/textbox';
import Radio from '@mui/material/Radio';
import { useState } from 'react';
import productsService from '../../../../../services/products.service';
import SimpleBackdrop from '../../../../../components/loader';
import ValidationDialog from '../../select-product/dialogs/validationDialog';
import { RadioSections, ProductType, AllowedImageFormats } from '../constants/previewCatalogue.constants';
import { useSelector } from 'react-redux';

const PreviewBlankSpaceDialog = ({
  open,
  handleClose,
  rowData,
  addBlankSpaceObject,
  productList,
  setIsSaveChangesDisable,
  setIsGenerateCatalogueDisabled
}) => {
  const [value, setValue] = React.useState(RadioSections.AddImage);

  const [imageFile, setImageFile] = useState(null);
  const [invalidImageFileFormat, setInvalidCoverFileFormat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [requiredValidation, setRequiredValidation] = useState(false);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState('')
  const [isDuplicateProduct, setIsDuplicateProduct] = useState(false);
  const [searchSKUField, setSearchSKUField] = useState('');
  const savedFilters = useSelector((state) => state.TemplateFiltersReducer);
  const country = savedFilters.selectedCountry != null ? savedFilters.selectedCountry.iso2Code : '';
  const warehouse = savedFilters.selectedWarehouse != null ? savedFilters.selectedWarehouse.code : '';
  const includeDiscontinuedProducts = savedFilters.includeDiscontinuedProducts;

  const resetState = () => {
    setProduct({});
    setImageFile(null);
    setSearchSKUField('');
    setInvalidCoverFileFormat(false);
    setIsValid(false);
    setRequiredValidation(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    resetState();
  };

  const handleCancelImageFile = () => {
    setImageFile(null); // Clear the selected file name
    setInvalidCoverFileFormat(false);
  };

  const handleImageFile = (event) => {
    const selectedFileName = event.target.files[0].name;
    const fileFormat = selectedFileName.split('.').pop().toLowerCase();
    if (!AllowedImageFormats.includes(fileFormat)) {
      setInvalidCoverFileFormat(true);
    } else {
      setInvalidCoverFileFormat(false);
    }
    const file = event.target.files[0];
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setImageFile({ fileName: selectedFileName, src: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const updateBlankSpace = () => {
    if (value === RadioSections.AddImage) {
      rowData = {
        ...rowData,
        ProductSku: 'Image',
        type: ProductType.CONTENT_PLACEHOLDER_IMAGE,
        CatalogueTitle: imageFile.fileName,
        ContentPlaceHolderImage: imageFile.src
      };
      addBlankSpaceObject(rowData);
    } else {
      rowData = {
        ...product,
        type: ProductType.PRODUCT,
        isBlankSpace: true,
        PageNumber: rowData.PageNumber,
        Sequence: rowData.Sequence,
        dimensions: rowData.dimensions
      };
      addBlankSpaceObject(rowData);
    }

    handleClose();
    
    // On success blank space edit Enable "Save Changes" & Disable "Generate Catalogue"
    setIsSaveChangesDisable(false);
    setIsGenerateCatalogueDisabled(true);
    
    // Hotfix: Adding timeout to reflect close popup first and then reset.
    setTimeout(() => {
      resetState();
      setValue(RadioSections.AddImage);
    }, 500);
  };

  const handleCancel = () => {
    handleClose();

    // Hotfix: Adding timeout to reflect close popup first and then reset.
    setTimeout(() => {
      resetState();
      setValue(RadioSections.AddImage);
    }, 500);
  };

  const handleSearchSKU = (event) => {
    setIsValid(false);
    const searchSKU = event.target.value.trim();
    setSearchSKUField(searchSKU);
  };

  /**
   * Add Product by SKU Fetch API integration and validations handler.
   * @returns 
   */
  const handleAddSKU = async () => {
    setLoading(true);

    let [product] = await productsService.getProducts({
      productSKU: searchSKUField,
      country,
      warehouse,
      includeDiscontinuedProducts: true
    });

    // No product found.
    if (!product) {
      setIsValid(true);
      setSearchSKUField('');
      setProduct({});
      setValidationMessage('Invalid SKU or restricted in selected region.')
      setRequiredValidation(false);
      setLoading(false);
      return;
    }

    //Check for discontinued product
    const isProductDiscontinued = !includeDiscontinuedProducts ? productsService.checkForDiscontinuedProducts(product, warehouse) : null ;
    if(isProductDiscontinued) {
      setIsValid(true);
      setSearchSKUField('');
      setProduct({});
      setValidationMessage('SKU is restricted as include discontinued filter is inactive.');
      setRequiredValidation(false);
      setLoading(false);
      return;
    }

    setIsValid(false);

    // Check for duplicates.
    const duplicateProduct = productList.filter((prd) => prd.ProductSku === product.ProductSku);
    if (duplicateProduct.length > 0) {
      setIsDuplicateProduct(true);
      setOpenValidationDialog(true);
      setSearchSKUField('');
      setProduct({});
      setLoading(false);
      return;
    }

    // Validate Required data for valid product.
    checkRequiredData(product);
    setLoading(false);
    return;
  };

  const closeValidationDialog = () => {
    setOpenValidationDialog(false);
    setSearchSKUField('');
  };

  const checkRequiredData = (product) => {
    let validateProducts =
      !product.CatalogueTitle?.trim() ||
      !product.CatalogueDescription?.trim() ||
      !product.PrimaryImage300dpi?.trim() ||
      !product.PrimaryImage72dpi?.trim();
    if (validateProducts) {
      setRequiredValidation(true);
      setProduct({});
      setIsValid(true);
    } else {
      setRequiredValidation(false);
      setProduct(product);
    }
    return;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleAddSKU();
  };

  /**
   *
   * @returns JSX element for add Image
   */
  const addImageSection = () => {
    return (
      <Grid
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '25px'
          
        }}>
        <Grid item='true'>
          <Typography variant='h2'>Add Image</Typography>
        </Grid>
        <Grid item='true' sx={{ width: '427px', marginTop: '10px' }}>
          <Fileupload
            id='blankImageFile'
            label='Lifestyle or Content Block'
            invalidFormat={invalidImageFileFormat}
            handleSelectedFile={handleImageFile}
            handleCancelFile={handleCancelImageFile}
            selectFile={imageFile != null ? imageFile.fileName : ''}
            isRequired={true}
          />
        </Grid>
      </Grid>
    );
  };

  /**
   *
   * @returns JSX element for Add Product by SKU
   */
  const addProductSection = () => {
    return (
      <form onSubmit={handleFormSubmit}>
        <Grid
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '25px'
          }}>
          <Grid item='true' sx={{marginBottom: '10px'}}>
            <Typography variant='h2'>Add Product by SKU</Typography>
          </Grid>
          <Grid item sx={{ marginTop: '16px',
              display: 'flex',
              '.addSKU-textbox': {
                width: '350px !important',
                alignItems: 'left',
                marginRight: '10px',
                '.MuiOutlinedInput-root': {
                  height: '45px !important'
                }
              },
              '.dialog-button': {
                width: '136px !important',
                height: '46px !important',
                marginTop: '25px'
              }
            }}>
            <Grid container>
              <Grid item sx={{ marginRight: '10px' }}>
                <BasicTextFields
                  isError={isValid}
                  errortext={isValid ? (requiredValidation ? '' : validationMessage) : ''}
                  onDataChange={handleSearchSKU}
                  inputValue={searchSKUField}
                  inputType='text'
                  label={'Search SKU'}
                  isRequired={true}
                  placeholder='SKU'
                  max={50}
                  className='addSKU-textbox'></BasicTextFields>
              </Grid>
              <Grid item alignSelf="center"></Grid>
                <ButtonComponent
                  variant='contained'
                  buttonText='Load Product'
                  btnSize='small'
                  disabled={!searchSKUField}
                  handleClick={handleAddSKU}
                  className='dialog-button'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item='true' sx={{ marginTop: '10px' }}>
            <Box
              sx={{
                border: '1px solid #E5E5E5',
                borderRadius: '5px',
                boxSizing: 'border-box',
                minHeight: '212px',
                width: '100%',
                marginTop: '25px'
              }}>
              {!product.ProductSku && !requiredValidation && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Brandon Grostesque Regular',
                    fontSize: '12px',
                    verticalAlign: 'center',
                    justifyContent: 'center',
                    marginTop: '10%'
                  }}>
                  Product details will be displayed here
                </Typography>
              )}
              {product.ProductSku && (
                <Grid
              container
              direction="row"
              sx={{
                border: '1px solid #cccccc',
                minHeight: '212px',
                borderRadius: '4px',
                padding: '16px',
              }}
            >
                  <Grid item xs={7} sx={{ marginRight: '10px' }}>
                    <Grid>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginRight: '8px'
                        }}>
                        Product Title:
                      </span>
                      <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'normal'
                          }}>
                          {' ' + product.CatalogueTitle}
                        </span>
                    </Grid>
                    <Grid sx={{ marginTop: '10px' }}>
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginRight: '8px'
                        }}>
                        Product SKU:
                      </span>
                      <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'normal'
                          }}>
                          {' ' + product.ProductSku}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item xs justifyContent='center' alignContent='center'>
                    <Box
                      >
                      <img
                        height={'180px'}
                        width={'180px'}
                        src={product.PrimaryImage72dpi}
                        alt='Primary Image'
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              {requiredValidation && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    verticalAlign: 'center',
                    justifyContent: 'center',
                    marginTop: '10%',
                    color: '#EF4242'
                  }}>
                  One or more required fields are missing for the SKU
                  <Typography>
                    (Required fields - Product Title, Primary Image - 72dpi and 300dpi, Product
                    Description)
                  </Typography>
                </Typography>
              )}
            </Box>
          </Grid>
      </form>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        sx={{
          '.MuiDialogContent-root': {
            padding: '0 24px 16px 24px'
          },
          '.MuiPaper-root': {
            maxWidth: '600px'
          }
        }}>
        <DialogTitle>
          <Typography variant='h2'>Edit Blank Space</Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl>
            {loading && <SimpleBackdrop loading={loading} />}
            <div>
              <span
                style={{
                  fontSize: '16px',
                  marginTop: '0px',
                  fontWeight: 'bold'
                }}>
                Space Available (w x h):
              </span>
              <span
                style={{
                  fontSize: '14px',
                  marginTop: '0px'
                }}>
                {' ' +
                  Math.ceil(rowData?.dimensions?.width) +
                  ' x ' +
                  Math.ceil(rowData?.dimensions?.height) +
                  ' px'}
              </span>
            </div>
            <div style={{marginTop: '20px'}}>
              <p
                style={{
                  fontSize: '16px',
                  margin: '0',
                  fontWeight: 'bold'
                }}>
                Select Option for Blank Space
              </p>
            </div>
            <div style={{marginTop:'10px'}}>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={value}
                name='controlled-radio-buttons-group'
                onChange={handleChange}>
                <FormControlLabel
                  value={RadioSections.AddImage}
                  control={<Radio />}
                  label='Image (Lifestyle or Content Block)'
                />
                <FormControlLabel
                  value={RadioSections.AddSKU}
                  control={<Radio />}
                  label='Product by SKU'
                />
              </RadioGroup>
            </div>
            {value === RadioSections.AddImage && <div id='addImage'>{addImageSection()}</div>}
            {value === RadioSections.AddSKU && <div id='addSKU'> {addProductSection()}</div>}
          </FormControl>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            marginLeft: 'auto',
            marginRight: '10px',
            marginTop:'20px'
          }}>
          <Grid
            sx={{
              '.dialog-button': {
                fontSize: '16px !important',
                padding: '7px',
                minWidth: '96px !important',
                height: '36px !important'
              }
            }}>
            <ButtonComponent
              variant='outlined'
              buttonText='Cancel'
              className='dialog-button'
              handleClick={handleCancel}></ButtonComponent>
          </Grid>
          <Grid
            sx={{
              '.dialog-button': {
                fontSize: '16px !important',
                padding: '7px',
                minWidth: '165px !important',
                height: '36px !important'
              }
            }}>
            <ButtonComponent
              disabled={
                value === RadioSections.AddImage
                  ? !imageFile || invalidImageFileFormat
                  : !product.ProductSku
              }
              handleClick={updateBlankSpace}
              variant='contained'
              className='dialog-button'
              buttonText='Update Blank Space'></ButtonComponent>
          </Grid>
        </div>
      </Dialog>
      <ValidationDialog
        open={openValidationDialog}
        closeValidationDialog={closeValidationDialog}
        enteredSKU={searchSKUField}
        isDuplicateSKU={isDuplicateProduct}></ValidationDialog>
    </>
  );
};

export default PreviewBlankSpaceDialog;
