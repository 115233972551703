import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import ButtonComponent from './button';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ConfirmDialog = (props) => {
  const { open, handleClose, handleConfirm } = props;

  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          padding: '7px',
          minWidth: '96px !important',
          fontSize: '16px !important',
          height: '36px !important'
        },
        '.delete-button': {
          padding: '7px',
          backgroundColor: '#ef4242',
          color: '#ffffff !important',
          boxShadow: 'none',
          minWidth: '96px !important',
          height: '36px !important',
          borderColor: '#ef4242',
          '&:hover': {
            backgroundColor: '#ef4242 !important',
            color: '#ffffff !important',
            borderColor: '#ef4242',
            opacity: '0.8'
          },
          fontSize: '16px !important',
        },
        '.MuiPaper-root': {
          width: '350px'
        },
        '.MuiDialogContent-root' : {
          padding: '0 24px 16px 24px'
        }
      }}>
      {/* <div style={{ padding: '16px 24px', margin: '5px', width: '350px' }}> */}
      <DialogTitle>
        <Typography variant='h2'>
          Confirm Action
        </Typography>
      </DialogTitle>
        {/* <p
          style={{
            fontFamily: 'Brandon Grostesque Regular',
            fontSize: '16px',
            marginBottom: '6px'
          }}>
          Are you sure you want to go back?
        </p> */}
        <DialogContent>
          <div>
            <p
              style={{
                fontFamily: 'Brandon Grostesque Regular',
                fontSize: '16px',
                marginBottom: '10px',
                marginTop: '0px'
              }}>
              All your unsaved data will be lost.
            </p>
          </div>
        </DialogContent>
        <DialogActions  sx={{
          padding: '0 16px 16px 16px'
        }}>
          <Grid container spacing={1}>
            <Grid item sx={{ marginRight: '5px' }}>
              <ButtonComponent
                variant='outlined'
                handleClick={handleClose}
                buttonText='Cancel'
                className='dialog-button'
                btnSize="small"
                ></ButtonComponent>
            </Grid>
            <Grid item xs={3}>
              <ButtonComponent
                variant='contained'
                buttonText='Confirm'
                className='delete-button'
                handleClick={handleConfirm}
                btnSize='small'
                ></ButtonComponent>
            </Grid>
          </Grid>
        </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
