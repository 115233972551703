import * as React from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch } from 'react-redux';
import { NotificationActions } from './common/notification/notificationSlice';

// DownloadFileComponent Component
const DownloadFileComponent = ({ record, downloadUrl, updateListMethod, downloadColumnName }) => {
  const dispatch = useDispatch();

  /**
   * It will create a display download button and inline loader during downloading process within data grid.
   * 
   * @param {Event} e 
   * @param {string} downloadUrl 
   * @param {number} rowId 
   * @param {function} updateListMethod 
   * @param {string} downloadColumnName 
   */
  function downloadPDF(e, downloadUrl, rowId) {
    if (!downloadUrl) {
      console.error('PDF file missing to download:');

      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: 'No PDF available to download.',
          type: 'error',
          timeout: 6000,
          typeStatus: 'Error'
        })
      );
    }

    // set loader true for grid item.
    updateListMethod((previousState) => {
      let data = [...previousState];
      let index = data.findIndex((item) => item.id === rowId);

      data[index] = {
        ...data[index],
        [downloadColumnName]: true
      };

      return [...data];
    });


    fetch(downloadUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => downloadSuccess(blob, rowId))
      .catch((error) => downloadError(error, rowId));
  }

  /**
   * Download file success handler.
   * 
   * @param {Blob} blob 
   * @param {number} rowId 
   */
  const downloadSuccess = (blob, rowId) => {
    // Create blob link to download
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    isIOS ? anchor.setAttribute('target', '_blank') : anchor.setAttribute('download', downloadUrl.split('/').slice(-1)[0]);

    // Append to html link element page
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode.removeChild(anchor);

    //resetting the status on particular id.
    updateListMethod((previousState) => {
      let data = [...previousState];
      let index = data.findIndex((item) => item.id === rowId);

      data[index] = {
        ...data[index],
        [downloadColumnName]: false
      };

      return [...data];
    });

  }

  /**
   * Download file error handler.
   * 
   * @param {Error} error 
   * @param {number} rowId
   */
  const downloadError = (error, rowId) => {
    //resetting the status on particular id.
    updateListMethod((previousState) => {
      let data = [...previousState];
      let index = data.findIndex((item) => item.id === rowId);

      data[index] = {
        ...data[index],
        [downloadColumnName]: false
      };

      return [...data];
    });

    console.error('Error downloading file:', error);
    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: 'Something went wrong while downloading catalogue, please try again later.',
        type: 'error',
        timeout: 6000,
        typeStatus: 'Error'
      })
    );
  }

  return (
    <div>
      {record[downloadColumnName] ?
        <CircularProgress color='secondary' /> :
        <IconButton
          color='primary'
          onClick={(e) => downloadPDF(e, downloadUrl, record.id)}
        >
          {<FileDownloadOutlinedIcon style={{ color: '#333333' }} />}
        </IconButton>}
    </div>);
};

export default DownloadFileComponent;
