import * as React from 'react';
import Button from '@mui/material/Button';

// Button Component
const ButtonComponent = (props) => {
  const { variant, buttonText, type, disabled, className, handleClick, btnSize } = props;

  const buttonStyle = {
    fontFamily: 'Brandon Grostesque Bold',
    fontSize: '20px',
    color: '#666666',
    textTransform: 'capitalize',
    boxShadow: 'none'
  };
  return (
    <Button
      variant={variant}
      fullWidth
      disabled={disabled}
      style={buttonStyle}
      type={type}
      className={className}
      onClick={handleClick}
      size={btnSize}>
      {buttonText}
    </Button>
  );
};

export default ButtonComponent;
