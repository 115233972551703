import React, { useEffect } from 'react';
import theme from '../../../../../config/theme';
import Img from '../../../../../assets/images/Done.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import Dialog from '@mui/material/Dialog';
import { ThemeProvider } from '@emotion/react';
import { DialogContent, DialogTitle } from '@mui/material';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SelectedProductAction } from '../../select-product/selectedProductList.slice';
import { AllProductAction } from '../../select-product/allProductList.slice';
import { SelectedProductCodeAction } from '../../select-product/selectedProductCodeList.slice';
import { TemplateAction } from '../../select-template/template.slice';
import { CoverAction } from '../../cover/selectCover.slice';
import { TemplateFiltersAction } from '../../generate-catalogue/filterSection.slice';
import { DirtyCheckAction } from '../../generate-catalogue/dirtyCheck.slice';
import ButtonComponent from '../../../../../components/button';
import { SequenceProductAction } from '../../sequence-product/sequenceProducts.slice';

export default function DoneDialog({ open }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(CoverAction.resetCoverDetails());
      dispatch(SelectedProductAction.resetSelectProducts());
      dispatch(SequenceProductAction.resetSequence());
      dispatch(AllProductAction.resetAllProducts());
      dispatch(SelectedProductCodeAction.resetSaveCode());
      dispatch(TemplateFiltersAction.resetFilterSection());
      dispatch(TemplateAction.resetTemplateSection());

      // The process has been completed so form is not dirty anymore
      dispatch(DirtyCheckAction.resetDirtyCheck());
    }
  }, [open]);

  const handleButtonClick = () => {
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      sx={{
        '.dialog-button': {
          padding: '7px',
          minWidth: '96px !important',
          fontSize: '16px !important',
          height: '36px !important'
        },
        '.delete-button': {
          padding: '7px',
          backgroundColor: '#ef4242',
          color: '#ffffff !important',
          boxShadow: 'none',
          minWidth: '96px !important',
          height: '36px !important',
          borderColor: '#ef4242',
          '&:hover': {
            backgroundColor: '#ef4242 !important',
            color: '#ffffff !important',
            borderColor: '#ef4242',
            opacity: '0.8'
          },
          fontSize: '16px !important'
        },
        '.MuiPaper-root': {
          width: '700px'
        },
        '.MuiDialogContent-root': {
          padding: '0 24px 16px 24px'
        }
      }}>
      <DialogTitle>
        <Typography variant='h2'></Typography>
      </DialogTitle>
      <DialogContent>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              flexGrow: 1,
              padding: '100px 50px 30px 50px',
              '.Mui-disabled': {
                background: '#f2f2f2'
              },
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <img src={Img} style={{ marginBottom: '37px', height: '130px', width: '130px' }}></img>
            <Typography variant='h1' style={{ marginBottom: '25px' }}>
              Your catalogue is coming together!
            </Typography>
            <span style={{ fontFamily: 'Brandon Grostesque Regular', fontSize: '18px' }}>
              You will receive a notification when the catalogue is ready and you can download it
              from the All Catalogues page.
            </span>
            <Grid container style={{ marginTop: '60px', marginRight: 'auto', marginLeft: 'auto' }}>
              <Grid item style={{ marginRight: '30px' }}>
                <ButtonComponent
                  variant='outlined'
                  buttonText='Create New Catalogue'
                  handleClick={handleButtonClick}></ButtonComponent>
              </Grid>
              <Grid item>
                <ButtonComponent
                  variant='contained'
                  buttonText='All Catalogues'
                  handleClick={() => navigate('/allCatalogues')}></ButtonComponent>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
}
