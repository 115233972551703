import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import ButtonComponent from '../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import SimpleBackdrop from '../../../../components/loader';
import PaginationWithIframe from '../../../../components/iframe-preview/iframe';
import ProductsStructure from './productsStructure';
import DoneDialog from './dialogs/DoneDialog';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import catalogueService from '../../../../services/catalogue.service';
import { PreviewSectionAction } from './previewSection.slice';
import { config } from '../../../../config/config';
import { AlgorithmVersions } from '../templateFirstEnums';
import moment from 'moment/moment';
import 'moment-timezone';

const previewCatalogue = ({ handleClick, handleBack }) => {
  const dispatch = useDispatch();
  const [sampleCatalogueHtml, setSampleCatalogueHtml] = useState('');

  // loader and done dialog flags
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [doneDialogFlag, setDoneDialogFlag] = useState(false);
  const [isGenerateCatalogueDisabled, setIsGenerateCatalogueDisabled] = useState(false);
  const [isSaveChangesDisable, setIsSaveChangesDisable] = useState(true);

  // Get required data from redux store
  const savedData = useSelector((state) => state);
  const selectedTemplate = savedData.TemplateReducer;
  const selectedFilters = savedData.TemplateFiltersReducer;
  const previewSectionData = savedData.PreviewSectionReducer;

  /**
   * Get the HTML preview from server and set to global variable.
   *
   * @param {*} products
   */
  const handleSaveChanges = async (products) => {
    setPreviewLoading(true);
    const requestPreview = {
      templateGridId: selectedTemplate.id,
      productData: products,
      section: selectedFilters.selectedSection?.name,
      selectedPriceList: selectedFilters.selectedPriceList? selectedFilters.selectedPriceList: null,
      isStartingPageRight: selectedFilters.isStartingPageRight?.value,
      startingPageIndex: selectedFilters.startingPage
        ? parseInt(selectedFilters.startingPage, 10)
        : null
    };

    try {
      const response = await catalogueService.generatePreviewV2(requestPreview);
      setSampleCatalogueHtml(response.data.html);

      // On success api preview response Disable "Save Changes" & Enable "Generate Catalogue"
      setIsSaveChangesDisable(true);
      setIsGenerateCatalogueDisabled(false);

      // Set Loaded Preview Products to Redux
      dispatch(
        PreviewSectionAction.savePreviewSection({
          previewProducts: products,
          html72dpi: response.data.html
        })
      );
    } catch (err) {
      console.error('Generate Preview API exception', err);
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: err.response.data.message,
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    }
    setPreviewLoading(false);
  };

  /**
   * Generate Catalogue Button Action handler.
   */
  const handleGenerateCatalogue = async () => {
    // loader start
    setLoading(true);

    const requestData = {
      productData: previewSectionData.previewProducts,
      section: selectedFilters.selectedSection?.name,
      category: selectedFilters.selectedCategory?.name,
      brand: selectedFilters.selectedBrand?.name,
      subCategory: selectedFilters.selectedSubCategory?.name,
      title: selectedFilters.title,
      isStartingPageRight: selectedFilters.isStartingPageRight?.value,
      startingPageIndex: selectedFilters.startingPage
        ? parseInt(selectedFilters.startingPage, 10)
        : null,
      season: selectedFilters.selectedSeason?.name,
      isCoverPagesIncluded: false,
      frontPageImage: null,
      backPageImage: null,
      frontPageLogo: null,
      backPageLogo: null,
      coverPageTitle: null,
      salesContact: null,
      salesContactEmail: null,
      templateGridId: selectedTemplate.id,
      createdAt: moment().tz(config.timeZone).toISOString(),
      html72dpi: previewSectionData.html72dpi,
      country: selectedFilters.selectedCountry?.name,
      iso2Country: selectedFilters.selectedCountry?.iso2Code,
      warehouse: selectedFilters.selectedWarehouse?.name,
      algorithmVersion: AlgorithmVersions.PRODUCT_FIRST,
      selectedPriceList: selectedFilters.selectedPriceList? selectedFilters.selectedPriceList: null
    };

    try {
      const apiResponse = await catalogueService.generateCatalogueV2(requestData);
      if (!apiResponse.id) {
        throw new Error(apiResponse.message);
      }

      // success popup open.
      setDoneDialogFlag(true);
    } catch (err) {
      console.error('Generate Catalogue API call exception', err);
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: err.response.data.message,
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    }

    // loader hide
    setLoading(false);
  };

  /**
   * On Back Button Action, reset redux state and go to back step.
   */
  const handleBackButton = () => {
    // reset state to redux
    dispatch(PreviewSectionAction.resetPreviewSection());

    // continue to parent back action
    handleBack();
  };

  return (
    <div>
      <Box style={{ padding: '0px 50px 30px 60px' }}>
        <DoneDialog open={doneDialogFlag} handleClose={() => setDoneDialogFlag(false)} />
        {loading && <SimpleBackdrop loading={loading} />}
        <Grid container spacing={2}>
          {/* Top container */}
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <Grid container spacing={2} columns={{ xl: 16, lg: 16 }}>
              {/* React table Section */}
              <Grid item xl={10} lg={10} md={12} sm={12}>
                <ProductsStructure
                  handleSaveChanges={handleSaveChanges}
                  isSaveChangesDisable={isSaveChangesDisable}
                  setIsSaveChangesDisable={setIsSaveChangesDisable}
                  setIsGenerateCatalogueDisabled={setIsGenerateCatalogueDisabled}
                />
              </Grid>
              <Divider
                variant='middle'
                orientation='vertical'
                flexItem
                sx={{ mr: '-1px', display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}
              />
              {/* Preview Frame Section */}
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                alignSelf='center'>
                <PaginationWithIframe
                  htmlContentString={sampleCatalogueHtml}
                  loading={previewLoading}
                  minZoom={0.2}
                  maxZoom={2}
                  zoomStep={0.1}></PaginationWithIframe>
              </Grid>
            </Grid>
          </Grid>
          {/* Bottom buttons */}
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item>
                <ButtonComponent
                  variant='outlined'
                  buttonText='Go Back'
                  handleClick={handleBackButton}></ButtonComponent>
              </Grid>
              <Grid item alignItems='right' justifyContent='right' style={{ marginLeft: 'auto' }}>
                <ButtonComponent
                  variant='outlined'
                  buttonText='Add Cover Page'
                  disabled={isGenerateCatalogueDisabled}
                  handleClick={handleClick}></ButtonComponent>
              </Grid>
              <Grid item>
                <ButtonComponent
                  variant='contained'
                  buttonText='Generate Catalogue'
                  disabled={isGenerateCatalogueDisabled}
                  handleClick={handleGenerateCatalogue}></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default previewCatalogue;
