import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { MaterialReactTable, MRT_FullScreenToggleButton } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import { SequenceProductAction } from './sequenceProducts.slice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';
import ProductCardInfoDialog from './dialogs/productCardInfo.dialog';
import ReadMore from '../../../../components/readMore';
import GridHeaderComponent from '../../../../components/gridHeader';

const sequenceProductList = ({ handleClick, handleBack }) => {
  const [productList, setproductList] = useState([]);
  const [openProductCardInfoDialog, setProductCardInfoDialog] = useState(false);
  const [productLength, setProductLength] = React.useState(0);

  const selectedTemplate = useSelector((state) => state.TemplateReducer);
  const savedProductList = useSelector((state) => state.SequenceProductReducer);

  const dispatch = useDispatch();

  const paragraphStyles = {
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'inline-block',
    marginRight: '20px'
  };

  const spanStyles = {
    fontSize: '16px',
    fontWeight: 'normal'
  };

  //should be memoized or stable
  useEffect(() => {
    setproductList(Object.values(savedProductList));
    setProductLength(Object.values(savedProductList).length);
  }, []);

  const productColumns = useMemo(() => [
    {
      accessorKey: 'Sequence',
      header: 'Sequence Number',
      Header: () => (
        <GridHeaderComponent headerText={'Sequence Number'} />
      ),
      size: 80,
      enableColumnFilter: false,
      muiTableBodyCellProps: {
        align: 'right'
      }
    },

    {
      accessorKey: 'PrimaryImage72dpi', //access nested data with dot notation
      header: 'Primary Image',
      Header: () => (
        <GridHeaderComponent headerText={'Primary Image'} />
      ),
      size: 80,
      enableColumnFilter: false,
      muiTableBodyCellProps: {
        align: 'center'
      },
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex'
          }}>
          <img
            height={65}
            width={100}
            src={renderedCellValue}
            loading='lazy'
            style={{ margin: '0px auto' }}
          />
        </Box>
      )
    },
    {
      accessorKey: 'ProductSku',
      header: 'Product SKU',
      size: 100,
      filterVariant: 'text',
      filterFn: 'contains',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'SKU' }
    },
    {
      accessorKey: 'CatalogueTitle', //normal accessorKey
      header: 'Product Title',
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
          <ReadMore renderedCellValue={renderedCellValue} />
        </Box>
      ),
      enableColumnFilter: false
    },
    {
      accessorKey: 'ProductCardAllocation',
      Header: () => (
        <GridHeaderComponent headerText={'Product Card Allocated'} />
      ),
      size: 150,
      enableColumnFilter: false
    }
  ]);

  const handleBackClick = () => {
    dispatch(SequenceProductAction.resetSequence());
    handleBack();
  };

  const handleNextClick = () => {
    dispatch(SequenceProductAction.saveSequence(productList));
    handleClick();
  };

  return (
    <div>
      <Box style={{ padding: '0px 50px 30px 60px' }}>
        <Grid container paddingBottom={2}>
          <Grid item='true' justifyContent='flex-start' xl={6} lg={6} sm={6}>
            {/* TODO: Uncomment this when sequence ui implementd */}
            {/* <BasicAlerts
              severity='info'
              message='Changing sequence will change the allocated product cards.'
            /> */}
            {selectedTemplate.title != null ? (
              <p style={paragraphStyles}>
                {'Template :'} <span style={spanStyles}>{selectedTemplate.title}</span>
              </p>
            ) : (
              ''
            )}
          </Grid>
          <Grid item container xl={6} lg={6} sm={6} alignItems='center' justifyContent='flex-end'>
            <InfoOutlinedIcon
              color='action'
              style={{ marginRight: '2px' }}
              fontSize='small'></InfoOutlinedIcon>
            <Link
              style={{ color: '#0284c7', textDecorationColor: '#0284c7', boxShadow: 'none' }}
              component='button'
              onClick={() => {
                setProductCardInfoDialog(true);
              }}>
              Products Cards
            </Link>
            <ProductCardInfoDialog
              open={openProductCardInfoDialog}
              handleClose={() => {
                setProductCardInfoDialog(false);
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ border: '1px solid #E7E7E7' }}>
          <Box
            sx={{
              '.MuiPaper-root': {
                boxShadow: 'none'
              },
              '.MuiTableContainer-root': {
                borderRadius: '4px',
                maxHeight: 'clamp(350px, calc(100vh - 400px), 9999px)'
              },
              '.MuiCollapse-root': {
                background: '#ffffff',
                padding: '5px',
                border: '1px solid #e5e5e5',
                borderRadius: '4px',
                marginTop: '10px'
              },
              '.MuiTableCell-root': {
                '&:first-child': {
                  textAlign: 'center',
                  verticalAlign: 'middle !important'
                },
                verticalAlign: 'top',
                padding: '8px'
              },
              '.Mui-TableHeadCell-Content': {
                justifyContent: 'center'
              },
              '.MuiButtonBase-root': {
                margin: '0px 0px 5px 2px'
              },
              '@media screen and (max-width: 991px)': {
                '.MuiFormControl-root': {
                  minWidth: '0px !important'
                }
              }
            }}>
            <MaterialReactTable
              columns={productColumns}
              data={productList}
              selectAllMode='all'
              enableStickyHeader={true}
              enableColumnActions={false}
              enableSorting={false}
              enableTopToolbar={true}
              enablePagination={false}
              enableRowVirtualization
              enableFacetedValues
              autoResetPageIndex={false}
              renderToolbarInternalActions={({ table }) => {
                return (
                  <Box>
                    <MRT_FullScreenToggleButton table={table} />
                  </Box>
                );
              }}
              rowNumberDisplayMode='original'
              // TODO: Uncomment this once sequencing is implemented
              // editingMode="cell"
              // enableEditing
              initialState={{
                showColumnFilters: true
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  fontFamily: 'Brandon Grostesque Regular',
                  fontSize: '16px'
                }
              }}
              muiTableHeadCellProps={{
                sx: {
                  border: '1px solid #e5e5e5',
                  background: '#f1f1f1',
                  fontFamily: 'Brandon Grostesque Bold',
                  fontSize: '16px'
                }
              }}
              muiTableContainerProps={{ sx: { maxHeight: '350px' } }}
              getRowId={(originalRow) => originalRow.ProductSku}
              renderBottomToolbar={() => (
                <Grid
                  sx={{ border: '1px solid #E7E7E7' }}
                  container
                  paddingTop={2}
                  paddingBottom={2}>
                  <Grid
                    item='true'
                    container
                    alignItems='center'
                    justifyContent='flex-start'
                    xl={4}
                    lg={4}
                    sm={6}>
                    {/* TODO: Uncomment this once sequencing is implemented*/}
                    {/*<Typography padding={1} variant='p'>
                        Double click in cell to edit Sequence Number.
                      </Typography> */}
                  </Grid>
                  <Grid
                    item
                    container
                    xl={8}
                    lg={8}
                    sm={6}
                    alignItems='center'
                    justifyContent='flex-end'>
                    <Typography padding={1} variant='p'>
                      Total Products : {productLength}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Box>
        </Box>
        <Grid marginTop={2} container>
          <Grid item='true'>
            <ButtonComponent
              variant='outlined'
              buttonText='Go Back'
              handleClick={handleBackClick}></ButtonComponent>
          </Grid>
          <Grid item='true' style={{ marginLeft: 'auto' }}>
            <ButtonComponent
              variant='contained'
              buttonText='Confirm'
              disabled={productLength < 1}
              handleClick={handleNextClick}></ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default sequenceProductList;
