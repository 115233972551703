import axios from 'axios';
import localstorageService from './localstorage.service';
import { config } from '../config/config';

/**
 * Get list of all users based on login user id
 * @param excludeId is number
 * @returns User[]
 */

const list = () => {
    const loginUser = localstorageService.getUser();
    return axios.get(`${config.api_url}/user/list`, { params: { excludeId: loginUser.id }}).then((res) => {
        return res.data;
    });
};

/**
 * 
 * @param userActiveStatus { id: number, isDeleted: boolean }
 * @returns boolean
 */
const updateUserStatus = (userActiveStatus) => {
    return axios.patch(`${config.api_url}/user/update-status`, userActiveStatus, {}).then((res) => {
        return res.data;
    });
}

export default {
    list,
    updateUserStatus
  };
  