import * as React from 'react';

// CopyUrlComponent Component
const GridHeaderComponent = ({ headerText, headerTextLine2, maxWidth, styles }) => {

  return (
    <div
      style={{
        whiteSpace: 'break-spaces',
        maxWidth,
        ...styles
      }}>
      <span>{headerText}</span>
      {headerTextLine2 ? (<><br /> <span>{headerTextLine2}</span></>) : ''}
    </div>
  );
};

export default GridHeaderComponent;
