import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function BasicAlerts({ severity, message }) {
  let backgroundColor;
  let textColor;

  switch (severity) {
    case 'error':
      backgroundColor = '#EF42421A';
      textColor = '#EF4242';
      break;

    case 'info':
      backgroundColor = '#0284C733';
      textColor = '#0284C7';
      break;

    default:
      backgroundColor = '#FFFFFF';
      textColor = '#000000';
  }

  return (
    <Stack>
      <Alert
        severity={severity}
        sx={{
          backgroundColor,
          color: textColor,
          fontFamily: 'Brandon Grostesque Regular',
          fontSize: '14px',
          lineHeight: '20px'
        }}
      >
        {message}
      </Alert>
    </Stack>
  );
}
