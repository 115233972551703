import React from 'react';
import { NotificationActions } from '../../components/common/notification/notificationSlice';
import { useDispatch } from 'react-redux';
import { config } from '../../config/config';
import localStorageService from '../../services/localstorage.service';

export default function EventComponent() {
  const dispatch = useDispatch();
  const baseURL = config.api_url;

  React.useEffect(() => {

    let user = localStorageService.getUser();
    if (!user.id) {
      return () => {
        console.log('SSE: Component can not load. User not found.');
      }
    }
    let eventSource = new EventSource(`${baseURL}/sse/${user.id}`);

    // Connection success.
    eventSource.onopen = (res) => {
      if (res.ok && res.status === 200) {
        console.log('SSE: onopen: Connection made.');
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.log('SSE: onopen: Connection error.');
      }
    }

    // Handle message success.
    eventSource.onmessage = (event) => {
      // will log 3 times for the data stream above
      if (event.data !== '') {
        let result = JSON.parse(event.data);
        console.log('SSE: onmessage: Message arrived.');
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: result.sendMessage,
            type: result.success ? 'success' : 'error',
            timeout: 10000, // in milliseconds.
            typeStatus: result.success ? 'Success' : 'Error'
          })
        );
      }
    };

    // SSE on error connection.
    eventSource.onerror = (err) => {
      console.log('SSE: onerror: Connection server side error.', err);
    }

    eventSource.onclose = () => {
      console.log('SSE: onclose: Connection closed by server.');
    }

    // Run a function when the component unmounts
    return () => {
      console.log('SSE: Component unload.');
      eventSource.close();
    };

  }, []); // No dependency(Empty array) means it will run only once on load.

  return <></>;
}
