import { createSlice } from '@reduxjs/toolkit';

export const templatesInitialState = {};

export const TemplateSlice = createSlice({
  name: 'selectedTemplate',
  initialState: templatesInitialState,
  reducers: {
    saveTemplate: (_state, action) => ({
      ...templatesInitialState,
      ...action.payload
    }),
    resetTemplateSection: () => ({
      ...templatesInitialState
    })
  }
});

export const TemplateAction = TemplateSlice.actions;
export const TemplateReducer = TemplateSlice.reducer;
