import React, { useState, useEffect, useRef } from 'react';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutIcon from '@mui/icons-material/ZoomOutOutlined';
import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';
import SimpleBackdrop from '../../components/loader';
import ButtonComponent from '../button';
import BasicTextFields from '../textbox';
import { Grid } from '@mui/material';
import IframeService from './iframe-service';

const PaginationWithIframe = ({ htmlContentString, minZoom, maxZoom, zoomStep, loading }) => {
  minZoom = minZoom || 0.1;
  maxZoom = maxZoom || 2;
  zoomStep = zoomStep || 0.1;

  // This will be calculated dynamicelly as per the screen size.
  const [defaultZoom, setDefaultZoom] = useState(0.7);
  const [zoom, setZoom] = useState(defaultZoom);
  const [nearestDimensions, setNearestDimensions] = useState(null);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(true); // Initially disabled
  const [isNextDisabled, setIsNextDisabled] = useState(false); // Initially enabled

  // Calculate the total number of the pages from provided html.
  const pageCount = (htmlContentString.match(/<div id="page-/g) || []).length;
  const [pagesArray, setPagesArray] = useState([]);
  const [htmlDom, setHtmlDom] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const iframeRef = useRef(null);

  /**
   * Scroll view to the specificied page.
   *
   * @param {number} elementId
   */
  const scrollToElement = (elementId) => {
    updatePage({ elementId, zoomValue: zoom });
  };

  /**
   * Zoom In by one step
   */
  const zoomIn = () => {
    let newZoom = zoom + zoomStep;
    if (newZoom > maxZoom) {
      return;
    }

    updatePage({ elementId: currentPage, zoomValue: newZoom.toFixed(2) });
  };

  /**
   * Zoom Out by one step
   */
  const zoomOut = () => {
    let newZoom = zoom - zoomStep;
    if (newZoom < minZoom) {
      return;
    }

    updatePage({ elementId: currentPage, zoomValue: newZoom.toFixed(2) });
  };

  /**
   * Previous page button action handler.
   */
  const handlePrevious = () => {
    let redirectPage = currentPage;
    // if beyond limit, set last page.
    if (redirectPage >= pageCount) {
      redirectPage = pageCount - 1;
    }
    scrollToElement(redirectPage + 1);
  };

  /**
   * Next page button action handler.
   */
  const handleNext = () => {
    let redirectPage = currentPage;
    // if below the limit, set first page.
    if (redirectPage <= 1) {
      redirectPage = 2;
    }
    scrollToElement(redirectPage - 1);
  };

  /**
   * Clear the body from the html
   * @param {string} htmlString
   * @returns [string]
   */
  const prepareDomParserBodySkeleton = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // clear body
    doc.body.innerHTML = '';

    // Set initial body styles.
    doc.body.style.transform = `scale(${defaultZoom})`;
    doc.body.style.transformOrigin = `top left`;
    doc.body.style.transition = `transform 0.3s ease`;
    doc.body.style.zoom = 1;
    doc.body.style.display = `block ruby`;
    doc.body.style.whiteSpace = `nowrap`;

    return doc;
  };

  /**
   * Fetch the html of each div and convert into array of pages.
   * @param {string} htmlString
   * @returns [string]
   */
  const prepareDivHtmlList = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select div elements with id starting with "page-"
    const pageDivElements = doc.querySelectorAll('div[id^="page-"]');

    // Extract text content from each matching div and prepare an array of it.
    const pageDivTextArray = Array.from(pageDivElements).map((div) => div.outerHTML);

    return pageDivTextArray;
  };

  /**
   * Set the appropriate page and zoom to the iFrame
   *
   * @param {number} elementId
   * @param {number} zoomValue
   */
  const updatePage = ({ elementId, zoomValue }) => {
    // convert first to float before any operations.
    zoomValue = parseFloat(zoomValue, 10);
    elementId = elementId || 1;
    const iframeContentWindow = iframeRef.current.contentWindow;

    // perform further if HTMLDom and iframeRef is available
    if (iframeContentWindow && htmlDom) {
      // Get appropriate page from array.
      htmlDom.body.innerHTML = pagesArray[elementId - 1] || '';

      // Apply appropriate zoom on body.
      htmlDom.body.style.transform = `scale(${zoomValue})`;

      // set relative height and width for html, body and iframe containers.
      if (nearestDimensions) {
        htmlDom.querySelector('html').style.height = `${nearestDimensions.iframeHeight}px`;
        htmlDom.querySelector('html').style.width = `${nearestDimensions.iframeWidth}px`;
        htmlDom.querySelector('body').style.height = `${nearestDimensions.iframeHeight}px`;
        htmlDom.querySelector('body').style.width = `${nearestDimensions.iframeWidth}px`;
        htmlDom.querySelectorAll('.vertical').forEach((hrElement) => {
          hrElement.style.width = `${IframeService.getVerticalGridLineSizes(zoomValue)}px`;
        });
        htmlDom.querySelectorAll('.horizontal').forEach((hrElement) => {
          hrElement.style.height = `${IframeService.getHorizontalGridLineSizes(zoomValue)}px`;
        });
        iframeRef.current.style.height = `${nearestDimensions.iframeHeight}px`;
        iframeRef.current.style.width = `${nearestDimensions.iframeWidth}px`;
      }

      // Set the srcdoc attribute with the content from the DOMParser
      iframeRef.current.srcdoc = htmlDom.documentElement.outerHTML;

      // update latest zoom and current page values.
      setZoom(zoomValue);
      setCurrentPage(+elementId);
    }
    const endPage = elementId || pageCount;
    setIsPreviousDisabled(elementId === 1);
    setIsNextDisabled(endPage === pageCount);
  };

  /**
   * Validate Page number range and numeric
   *
   * @param {*} event
   * @returns number
   */
  const validatePageNumber = (inputValue) => {
    if (isNaN(inputValue)) {
      return 0;
    }

    inputValue = parseInt(inputValue, 10);
    if (inputValue >= 0 && inputValue <= pageCount) {
      return inputValue;
    }

    if (inputValue <= 0) {
      return 1;
    }

    if (inputValue > pageCount) {
      return pageCount;
    }

    // Set actual input for valid.
    return currentPage;
  };

  useEffect(() => {
    const tempNearestDimensions = IframeService.getScreenWidthDimensions(screen.width);
    setNearestDimensions(tempNearestDimensions);

    setZoom(tempNearestDimensions.defaultZoom);
    setDefaultZoom(tempNearestDimensions.defaultZoom);

    // on load scroll to first page.
    setPagesArray(prepareDivHtmlList(htmlContentString));
    setHtmlDom(prepareDomParserBodySkeleton(htmlContentString, tempNearestDimensions.defaultZoom));
  }, [htmlContentString]);

  useEffect(() => {
    scrollToElement(1);
  }, [htmlDom, pagesArray]);

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      // Assigned position: 'relative' so that loader will be shown only above the component and not the full screen.
      sx={{ position: 'relative' }}>
      <Grid item xl={10} lg={10} sm={12}>
        {loading && <SimpleBackdrop loading={loading} isInternalComponentLoader={true} />}
      </Grid>
      <Grid item>
        <iframe
          style={{
            border: '1px solid #cccccc',
            borderRadius: '4px',
            opacity: 1,
            overflow: 'auto'
          }}
          ref={iframeRef}
          key={htmlContentString}
          title={`Page ${currentPage + 1}`}
          srcDoc={htmlContentString}
          // sandbox="allow-scripts allow-same-origin"
          align='center'
        />
      </Grid>

      <Grid item>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={1}
          sx={{
            '.minWidthActionButtons': { minWidth: '10px' },
            '.grayButton': { color: '#CCCCCC !important', minWidth: '10px' }
          }}>
          <Grid item>
            <ButtonComponent
              variant='text'
              className='minWidthActionButtons'
              buttonText={<ZoomOutIcon fontSize='small' />}
              handleClick={() => {
                zoomOut();
              }}
            />
          </Grid>

          <Grid item>
            <ButtonComponent
              variant='text'
              className='minWidthActionButtons'
              buttonText={<CenterFocusStrongOutlinedIcon fontSize='small' />}
              handleClick={() => updatePage({ elementId: currentPage, zoomValue: defaultZoom })}
            />
          </Grid>

          <Grid item>
            <ButtonComponent
              variant='text'
              className='minWidthActionButtons'
              buttonText={<ZoomInIcon fontSize='small' />}
              handleClick={() => {
                zoomIn();
              }}
            />
          </Grid>

          <Grid
            item
            sx={{
              width: '15%',
              '.pageNumberTextbox input[type=text]': {
                textAlign: 'center !important'
              }
            }}>
            <BasicTextFields
              inputType='text'
              className='pageNumberTextbox'
              inputValue={currentPage}
              inputProps={{
                onFocus: (event) => {
                  event.target.select();
                },
                onBlur: (event) => {
                  const inputValue = validatePageNumber(event.target.value);
                  scrollToElement(+inputValue);
                },
                onKeyUp: (event) => {
                  if (event.keyCode == 13) {
                    const pageNumber = validatePageNumber(event.target.value);
                    scrollToElement(+pageNumber);
                  }
                }
              }}
              onDataChange={(event) => {
                setCurrentPage(event.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <span>of {pageCount}</span>
          </Grid>

          <Grid item>
            <ButtonComponent
              variant='text'
              buttonText={<KeyboardArrowUpOutlinedIcon fontSize='small' />}
              className={isPreviousDisabled ? 'grayButton' : 'minWidthActionButtons'}
              disabled={isPreviousDisabled}
              handleClick={handleNext}
            />
          </Grid>

          <Grid item>
            <ButtonComponent
              variant='text'
              buttonText={<KeyboardArrowDownOutlinedIcon fontSize='small' />}
              disabled={isNextDisabled}
              handleClick={handlePrevious}
              className={isNextDisabled ? 'grayButton' : 'minWidthActionButtons'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaginationWithIframe;
