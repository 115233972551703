import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

export const login = createAsyncThunk('auth/login', async ({ username, password }, thunkAPI) => {
  try {
    const data = await AuthService.login(username, password);
    return { user: data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error(message);
    // thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: null, user: null };

export const LoginSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    }
  }
});

export const LoginActions = LoginSlice.actions;
export const LoginReducer = LoginSlice.reducer;
