import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/system/Unstable_Grid';
import ButtonComponent from '../../../../components/button';
import SimpleBackdrop from '../../../../components/loader';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '../../../../config/config';
import { TemplateAction } from './template.slice';
import 'moment-timezone';
import templateService from '../../../../services/template.service';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import { DirtyCheckAction } from '../generate-catalogue/dirtyCheck.slice';


const SelectTemplate = ({ handleClick }) => {

  const savedData = useSelector((state) => state);
  const savedTemplate = savedData.TemplateReducer;

  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [templateList, setTemplatesList] = useState([]);
  const dispatch = useDispatch();
  const dirtyCheckReducer = useSelector((state) => state.DirtyCheckReducer);

  React.useEffect(() => {
    getTemplates();
    if(savedTemplate) {
      setSelectedTemplate(savedTemplate);
    }
  }, []);

  /**
   * fetch all templates.
   */
  const getTemplates = async() => {
    try{
      setLoading(true);
      let templates = await templateService.getTemplateList();
      setTemplatesList(templates.filter(obj => obj.thumbnailImage.length));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Something went wrong, please try again later.',
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    }
  }

  /**
   * Template select Button click handler.
   */
  const onSelectTemplate = () => {
    // Set selectedTemplateId to Redux
    dispatch(TemplateAction.saveTemplate(selectedTemplate));
    // On template select form will be dirty.
    if (!dirtyCheckReducer.isDirty) {
      dispatch(DirtyCheckAction.saveDirtyCheck({ isDirty: true }));
    }
    handleClick();
  }

  return (
    <>
      {loading && <SimpleBackdrop loading={loading} />}
      <Box
        marginX={8}
        marginY={'30px'}
        paddingX={4}
        paddingBottom={2}
        overflow={'auto'}
        overflowy={'scroll'}
        maxHeight={'clamp(350px, calc(100vh - 343px), 9999px)'}
        sx={{
          border: '1px solid #E7E7E7',
          ':focus': {
            outlineColor: '#FFC845 !important',
            outlineStyle: 'solid !important',
            outlineWidth: '2px !important'
          }
        }}
      >
        <Grid container direction='row' justifyContent={'space-between'} marginTop={1}>
          <Grid item="true">
            <h3>Pick a Template</h3>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='left'
          paddingTop={{ xs: 2, md: 0 }}
          paddingX={4}
          spacing={{ xs: 2, md: 5 }}
          columns={{ xs: 4, md: 10 }}
        >
          {templateList.map((template, index) => (
            <Grid item="true" xs={2} md={2} key={index} id={`grid-item-${index}`} >
                <Paper
                  elevation={0}
                  sx={{
                    padding: template.title === selectedTemplate.title ? 2 : 2.4,
                    border:
                      template.id === selectedTemplate.id
                        ? '4px solid #FFC845'
                        : '1px solid #E7E7E7',
                    cursor: 'pointer',
                    textAlign: 'center',
                    ':focus': {
                      outlineColor: '#FFC845 !important',
                      outlineStyle: 'solid !important',
                      outlineWidth: '2px !important'
                    }
                  }}
                  onFocus={() => {
                    setSelectedTemplate(template);
                  }}
                  tabIndex={0}
                >
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100%'
                    }}
                    src={config.api_url + template.thumbnailImage}
                    alt='image'
                  />
                  <b>{template.title}</b>
                </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid
        container
        xs={12}
        padding={'0px 60px 0px 60px'}
      >
        <Grid item="true" style={{ marginLeft: 'auto' }}>
            <ButtonComponent
              variant='contained'
              disabled={!selectedTemplate.id}
              buttonText='Select Template'
              handleClick={onSelectTemplate}
            ></ButtonComponent>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectTemplate;
