const standardSizes = [
  {
    screenWidth: 768,
    defaultZoom: 0.9,
    iframeWidth: 650,
    iframeHeight: 909
  },
  {
    screenWidth: 900,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 1024,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 1080,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 1200,
    defaultZoom: 0.6,
    iframeWidth: 434,
    iframeHeight: 604
  },
  {
    screenWidth: 1366,
    defaultZoom: 0.6,
    iframeWidth: 434,
    iframeHeight: 604
  },
  {
    screenWidth: 1440,
    defaultZoom: 0.6,
    iframeWidth: 434,
    iframeHeight: 604
  },
  {
    screenWidth: 1536,
    defaultZoom: 0.6,
    iframeWidth: 434,
    iframeHeight: 604
  },
  {
    screenWidth: 1600,
    defaultZoom: 0.6,
    iframeWidth: 434,
    iframeHeight: 604
  },
  {
    screenWidth: 1920,
    defaultZoom: 0.9,
    iframeWidth: 650,
    iframeHeight: 909
  },
  {
    screenWidth: 2160,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 2560,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 3840,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  },
  {
    screenWidth: 4096,
    defaultZoom: 1,
    iframeWidth: 724,
    iframeHeight: 1010
  }
];

const standardGridLineSizes = {
  horizontal: [{
    minZoom: 0.7,
    height: 1
  },
  {
    minZoom: 0.4,
    height: 2
  }, {
    minZoom: 0,
    height: 3
  }],
  vertical: [{
    minZoom: 0.9,
    width: 1
  },
  {
    minZoom: 0.5,
    width: 2
  }, {
    minZoom: 0,
    width: 3
  }]
}

/**
 * Get the nearest record of width
 * @param {number} screenWidth
 * @returns
 */
const getScreenWidthDimensions = (screenWidth) => {
  return standardSizes.reduce((closest, current) => {
    const currentDiff = Math.abs(current.screenWidth - screenWidth);
    const closestDiff = Math.abs(closest.screenWidth - screenWidth);

    return currentDiff < closestDiff ? current : closest;
  });
};

/**
 * Get the Vertical Grid Line Height For Specific Zoom
 * @param {number} zoomValue
 * @returns
 */
const getVerticalGridLineSizes = (zoomValue) => {
  return standardGridLineSizes.vertical.find((ele) => ele.minZoom < zoomValue).width
};

/**
 * Get the Horizontal Grid Line Height For Specific Zoom
 * @param {number} zoomValue
 * @returns
 */
const getHorizontalGridLineSizes = (zoomValue) => {
  return standardGridLineSizes.horizontal.find((ele) => ele.minZoom < zoomValue).height
};

export default {
  standardSizes,
  getScreenWidthDimensions,
  standardGridLineSizes,
  getHorizontalGridLineSizes,
  getVerticalGridLineSizes
};
