export const RadioSections = {
  AddImage: 'image',
  AddSKU: 'sku'
};

export const ProductType = {
  PRODUCT: 'PRODUCT',
  BLANK_SPACE: 'BLANK_SPACE',
  CONTENT_PLACEHOLDER_IMAGE: 'CONTENT_PLACEHOLDER_IMAGE'
};

export const AllowedImageFormats = ['jpeg', 'jpg', 'png'];

export const RegEx = {
  PositiveNumber: /^\s*(0*1|0*[1-9]\d*)\s*$|^$/,
  Email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
};

export const BlankSpaceColors = {
  Filled: '#EDF7F7',
  Empty: '#fef2f2'
}

export const pageSide = [
  { name: 'Left', value: false },
  { name: 'Right', value: true }
];
