import axios from 'axios';
import localstorageService from './localstorage.service';
import { config } from '../config/config';

const login = async (username, password) => {
  const response = await axios.post(`${config.api_url}/auth/login`, {
    email: username,
    password
  });
  if (response.data.accessToken) {
    localstorageService.setUser({ ...response.data, email: username });
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem('user');
};

const getUsers = () => {
  return axios.get(`${config.api_url}/user/1`).then((res) => {
    return res.data;
  });
};

export default {
  login,
  logout,
  getUsers
};
