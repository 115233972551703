import { createSlice } from '@reduxjs/toolkit';

export const dirtyCheckInitialState = {
    isDirty: false
};

export const DirtyCheckSlice = createSlice({
    name: 'dirtyCheck',
    initialState: dirtyCheckInitialState,
    reducers: {
        saveDirtyCheck: (_state, action) => ({
            ...dirtyCheckInitialState,
            ...action.payload
        }),
        resetDirtyCheck: () => ({
            ...dirtyCheckInitialState
        })
    }
});

export const DirtyCheckAction = DirtyCheckSlice.actions;
export const DirtyCheckReducer = DirtyCheckSlice.reducer;
