import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { config } from '../../../../config/config';
import theme from '../../../../config/theme';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import ButtonComponent from '../../../../components/button';
import BasicTextFields from '../../../../components/textbox';
import Fileupload from '../../../../components/fileUpload';
import SimpleBackdrop from '../../../../components/loader';
import { CoverAction } from './selectCover.slice';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import catalogueService from '../../../../services/catalogue.service';
import DoneDialog from '../preview-catalogue/dialogs/DoneDialog';
import { AlgorithmVersions } from '../templateFirstEnums';
import moment from 'moment/moment';
import 'moment-timezone';
import { AllowedImageFormats, RegEx } from '../preview-catalogue/constants/previewCatalogue.constants';

export default function SelectCover({ handleBack }) {
  // set front page input values
  const [coverTitle, setCoverTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const [coverFile, setCoverFile] = useState(null);
  const [invalidCoverFileFormat, setInvalidCoverFileFormat] = useState(false);

  const [coverLogo, setCoverLogo] = useState(null);
  const [invalidCoverLogoFormat, setInvalidCoverLogoFormat] = useState(false);

  const [backCover, setBackCover] = useState(null);
  const [invalidBackFileFormat, setInvalidBackCoverFormat] = useState(false);

  const [backLogo, setBackLogo] = useState(null);
  const [invalidBackLogoFormat, setInvalidBackLogoFormat] = useState(false);

  const [openDoneDialog, setDoneDialog] = React.useState(false);

 
  // set back page input values
  const [salesContact, setSalesContact] = useState('');
  const [salesContactEmail, setSalesContactEmail] = useState('');

  // Error Flags
  const [isSalesContactError, setSalesContactError] = useState(true);
  const [isSalesContactEmailError, setSalesContactEmailError] = useState(true);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();

  //Read the data from redux state
  const savedData = useSelector((state) => state);
  const selectedFilters = savedData.TemplateFiltersReducer;
  const selectCoverPage = savedData.CoverReducer;
  const selectedTemplate = savedData.TemplateReducer;
  const previewSectionData = savedData.PreviewSectionReducer;

  useEffect(() => {
    setCoverTitle(selectedFilters.title);
    if (selectCoverPage.coverFile != null) {
      setCoverFile(selectCoverPage.coverFile);
    }
    if (selectCoverPage.coverLogo != null) {
      setCoverLogo(selectCoverPage.coverLogo);
    }
    if (selectCoverPage.salesContact != '') {
      setSalesContactError(false);
      setSalesContact(selectCoverPage.salesContact);
    }
    if (selectCoverPage.salesContactEmail != '') {
      setSalesContactEmailError(false);
      setSalesContactEmail(selectCoverPage.salesContactEmail);
    }

    if (selectCoverPage.backCover != null) {
      setBackCover(selectCoverPage.backCover);
    }

    if (selectCoverPage.backLogo != null) {
      setBackLogo(selectCoverPage.backLogo);
    }
  }, [loading]);

  //front page handler
  const handleCoverPageFile = (event) => {
    const selectedFileName = event.target.files[0].name;
    const fileFormat = selectedFileName.split('.').pop().toLowerCase();
    if (!AllowedImageFormats.includes(fileFormat)) {
      setInvalidCoverFileFormat(true);
    } else {
      setInvalidCoverFileFormat(false);
    }
    const file = event.target.files[0];
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setCoverFile({ fileName: selectedFileName, src: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleCancelCoverPageFile = () => {
    setCoverFile(null); // Clear the selected file name
    setInvalidCoverFileFormat(false);
  };

  const handleCoverLogoFile = (event) => {
    const selectedCoverLogo = event.target.files[0].name;
    const fileFormat = selectedCoverLogo.split('.').pop().toLowerCase();
    if (!AllowedImageFormats.includes(fileFormat)) {
      setInvalidCoverLogoFormat(true);
    } else {
      setInvalidCoverLogoFormat(false);
    }
    const file = event.target.files[0];
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setCoverLogo({ fileName: selectedCoverLogo, src: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleCancelCoverLogoFile = () => {
    setCoverLogo(null);
    setInvalidCoverLogoFormat(false);
  };

  //back page handler
  const handleSalesContact = (event) => {
    let length = event.target.value.trim().length;
    length == 0 ? setSalesContactError(true) : setSalesContactError(false);
    setSalesContact(event.target.value);
  };

  const handleSalesContactEmail = (event) => {
    setSalesContactEmail(event.target.value);
    let length = event.target.value.trim().length;
    length == 0 ? setSalesContactEmailError(true) : setSalesContactEmailError(false);
    setSalesContactEmail(event.target.value);
  };

  const handleBackFile = (event) => {
    const selectedBackFile = event.target.files[0].name;
    const fileFormat = selectedBackFile.split('.').pop().toLowerCase();
    if (!AllowedImageFormats.includes(fileFormat)) {
      setInvalidBackCoverFormat(true);
      setBackCover({ fileName: selectedBackFile });
    } else {
      setInvalidBackCoverFormat(false);
    }
    const file = event.target.files[0];
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && AllowedImageFormats.includes(fileFormat)) {
          setBackCover({ fileName: selectedBackFile, src: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleCancelBackFile = () => {
    setBackCover(null);
    setInvalidBackCoverFormat(false);
  };

  const handleBackLogo = (event) => {
    const selectedBackLogo = event.target.files[0].name;
    const fileFormat = selectedBackLogo.split('.').pop().toLowerCase();
    if (!AllowedImageFormats.includes(fileFormat)) {
      setInvalidBackLogoFormat(true);
    } else {
      setInvalidBackLogoFormat(false);
    }
    const file = event.target.files[0];
    let fileReader;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setBackLogo({ fileName: selectedBackLogo, src: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleCancelBackLogoFile = () => {
    setBackLogo(null);
    setInvalidBackLogoFormat(false);
  };

  /**
   * Submit catalogue data to API
   */
  const generateCatalogue = async () => {
    // Start API call.
    setLoading(true);
    const requestData = {
      productData: previewSectionData.previewProducts,
      section: selectedFilters.selectedSection?.name,
      category: selectedFilters.selectedCategory?.name,
      brand: selectedFilters.selectedBrand?.name,
      subCategory: selectedFilters.selectedSubCategory?.name,
      title: selectedFilters.title,
      isStartingPageRight: selectedFilters.isStartingPageRight?.value,
      startingPageIndex: selectedFilters.startingPage
        ? parseInt(selectedFilters.startingPage, 10)
        : null,
      season: selectedFilters.selectedSeason?.name,
      isCoverPagesIncluded: true,
      frontPageImage: coverFile?.src,
      backPageImage: backCover?.src,
      frontPageLogo: coverLogo?.src,
      backPageLogo: backLogo?.src,
      coverPageTitle: savedData.TemplateFiltersReducer.title,
      salesContact: salesContact,
      salesContactEmail: salesContactEmail,
      templateGridId: selectedTemplate.id,
      createdAt: moment().tz(config.timeZone).toISOString(),
      html72dpi: previewSectionData.html72dpi,
      country: selectedFilters.selectedCountry?.name,
      iso2Country: selectedFilters.selectedCountry?.iso2Code,
      warehouse: selectedFilters.selectedWarehouse?.name,
      algorithmVersion: AlgorithmVersions.PRODUCT_FIRST,
      selectedPriceList: selectedFilters.selectedPriceList? selectedFilters.selectedPriceList: null
    };

    try {
      const apiResponse = await catalogueService.generateCatalogueV2(requestData);
      if (!apiResponse.id) {
        throw new Error(apiResponse.message);
      }

      // loading completed.
      setLoading(false);

      // success popup open.
      setDoneDialog(true);
    } catch (err) {
      // loading completed.
      setLoading(false);
      console.error('Generate Catalogue API call exception', err);
      dispatch(
        NotificationActions.addNotification({
          open: true,
          message: err.response.data.message,
          type: 'error',
          timeout: 6000,
          typeStatus: 'Error'
        })
      );
    }
  };

  /**
   * Generate Catalogue Button click handler.
   */
  const onGenerateButtonClickAction = () => {
    // Sanatize Email & Contact
    if (!salesContactEmail.match(RegEx.Email) || salesContactEmail.trim().length > 70) {
      setSalesContactEmailError(true);
    } else {
      setSalesContactEmailError(false);
    }

    salesContact.trim().length > 40 ? setSalesContactError(true) : setSalesContactError(false);

    if (
      salesContact.trim().length > 40 ||
      !salesContactEmail.match(RegEx.Email) ||
      salesContactEmail.trim().length > 70
    ) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
      let coverValues = {
        salesContact,
        salesContactEmail,
        coverFile,
        coverLogo,
        backCover,
        backLogo
      };
      dispatch(CoverAction.saveCoverDetails(coverValues));

      // API call.
      generateCatalogue();
    }
  };

  const handleBackButtonClick = () => {
    dispatch(CoverAction.resetCoverDetails());
    handleBack();
  };

  // const handleSkipButtonClick = () => {
  //   dispatch(CoverAction.resetCoverDetails());
  //   handleSkip();
  // };

  return (
    <ThemeProvider theme={theme}>
      {loading && <SimpleBackdrop loading={loading} />}
      <Box
        sx={{
          flexGrow: 1,
          padding: '10px 50px 30px 50px',
          '.Mui-disabled': {
            background: '#f2f2f2'
          },
          '@media screen and (max-width: 991px)': {
            '.grid-item': {
              marginBottom: '30px !important'
            }
          }
        }}>
        <div style={{ margin: '0px auto 50px auto' }}>
          <Grid
            xs={12}
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Grid item='true' xs={12}>
              <Typography variant='h2'>Front Page</Typography>
            </Grid>
            <Grid item='true' xs={12}>
              <Typography variant='p' style={{ fontFamily: 'Brandon Grostesque Bold' }}>
                Title :{' '}
                <span style={{ fontFamily: 'Brandon Grostesque Regular' }}>{coverTitle}</span>
              </Typography>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <Fileupload
                id='coverPageFile'
                label='Cover Image'
                invalidFormat={invalidCoverFileFormat}
                handleSelectedFile={handleCoverPageFile}
                handleCancelFile={handleCancelCoverPageFile}
                selectFile={coverFile != null ? coverFile.fileName : ''}
                isRequired={true}></Fileupload>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <Fileupload
                id='coverLogoFile'
                label='Logo'
                invalidFormat={invalidCoverLogoFormat}
                handleSelectedFile={handleCoverLogoFile}
                handleCancelFile={handleCancelCoverLogoFile}
                selectFile={coverLogo != null ? coverLogo.fileName : ''}
                isRequired={true}></Fileupload>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}></Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}></Grid>
          </Grid>
        </div>
        <div style={{ margin: '0px auto 58px auto' }}>
          <Grid
            xs={12}
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Grid item='true' xs={12}>
              <Typography variant='h2'>Back Page</Typography>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <BasicTextFields
                  isError={isSalesContactError && showError}
                  errortext='Maximum characters allowed - 40'
                  label='Sales Contact'
                  onDataChange={handleSalesContact}
                  inputValue={salesContact}
                  isRequired={true}
                  placeholder='Name'></BasicTextFields>
              </div>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <div className='grid-item'>
                <BasicTextFields
                  label='Sales Contact Email'
                  errortext='Accepted email - name@company.com & 70 characters'
                  isError={isSalesContactEmailError && showError}
                  onDataChange={handleSalesContactEmail}
                  inputValue={salesContactEmail}
                  isRequired={true}
                  placeholder='Email'></BasicTextFields>
              </div>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <Fileupload
                label='Cover Image'
                id='backPageFile'
                invalidFormat={invalidBackFileFormat}
                handleSelectedFile={handleBackFile}
                handleCancelFile={handleCancelBackFile}
                selectFile={backCover != null ? backCover.fileName : ''}></Fileupload>
            </Grid>
            <Grid item='true' xs={6} sm={6} md={6} lg={3}>
              <Fileupload
                label='Logo'
                id='backLogoFile'
                invalidFormat={invalidBackLogoFormat}
                handleSelectedFile={handleBackLogo}
                handleCancelFile={handleCancelBackLogoFile}
                selectFile={backLogo != null ? backLogo.fileName : ''}
                isRequired={true}></Fileupload>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container>
            <Grid item='true'>
              <ButtonComponent
                variant='outlined'
                buttonText='Go Back'
                handleClick={handleBackButtonClick}></ButtonComponent>
            </Grid>
            <Grid item='true' style={{ marginLeft: 'auto' }}>
              <ButtonComponent
                variant='contained'
                buttonText='Generate Catalogue'
                handleClick={onGenerateButtonClickAction}
                disabled={
                  !(
                    coverFile &&
                    !invalidCoverFileFormat &&
                    coverLogo &&
                    !invalidCoverLogoFormat &&
                    backLogo &&
                    !invalidBackLogoFormat &&
                    !isSalesContactError &&
                    !isSalesContactEmailError
                  )
                }></ButtonComponent>
            </Grid>
          </Grid>
        </div>
      </Box>
      <DoneDialog open={openDoneDialog} handleClose={() => setDoneDialog(false)} />
    </ThemeProvider>
  );
}
