import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { MaterialReactTable,MRT_FullScreenToggleButton } from 'material-react-table';
import productsService from '../../../../services/products.service';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../../../components/button';
import SimpleBackdrop from '../../../../components/loader';
import Grid from '@mui/system/Unstable_Grid';
import { AllProductAction } from './allProductList.slice';
import { SelectedProductAction } from './selectedProductList.slice';
import { SelectedProductCodeAction } from './selectedProductCodeList.slice';
import { SequenceProductAction } from '../sequence-product/sequenceProducts.slice';
import RequiredDetailsDialog from './dialogs/requiredDetailsDialog';
import { NotificationActions } from '../../../../components/common/notification/notificationSlice';
import { Typography } from '@mui/material';
import BasicTextFields from '../../../../components/textbox';
import ValidationDialog from './dialogs/validationDialog';
import templateService from '../../../../services/template.service';
import ReadMore from '../../../../components/readMore';

const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
  return [ htmlElRef,  setFocus ];
}

const selectProductList = ({ handleClick, handleBack }) => {
  const [loading, setLoading] = useState(false);
  const paragraphStyles = {
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'inline-block',
    marginRight: '40px'
  };

  const spanStyles = {
    fontSize: '16px',
    fontWeight: 'normal'
  };
  const dispatch = useDispatch();

  const [productList, setproductList] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [openRequiredDetailsDialog, setOpenRequiredDetailsDialog] = useState(false);
  const [invalidProducts, setInvalidProducts] = useState([]);
  const [productLength, setProductLength] = React.useState(0);
  const [hasRows, setHasRows] = useState(null);
  const [searchSKUField, setSearchSKUField] = useState('');
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isDuplicateProduct, setIsDuplicateProduct] = useState(false);
  const [duplicateProduct, setDuplicateProduct] = useState([]);
  const [focusFlag, setFocusFlag] = useState(false);

   //store pagination state in your own state
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  // For table reference
  const tableRef = React.createRef();
  const [searchSKUInputRef, setSearchSKUFocus] = UseFocus();

  useEffect(() => {
    // scroll the table container to top
    tableRef.current?.refs.tableContainerRef.current.scrollTo(0, 0)
  }, [pagination.pageIndex, pagination.pageSize]);

  const closeRequiredDetailsDialog = () => {
    setOpenRequiredDetailsDialog(false);
  };

  const savedFilters = useSelector((state) => state.TemplateFiltersReducer);
  const Section = savedFilters.selectedSection != null ? savedFilters.selectedSection.name : '';
  const Brand = savedFilters.selectedBrand != null ? savedFilters.selectedBrand.name : '';
  const Category = savedFilters.selectedCategory != null ? savedFilters.selectedCategory.name : '';
  const SubCategory = savedFilters.selectedSubCategory != null ? savedFilters.selectedSubCategory.name : '';
  const Range = savedFilters.selectedRange != null? savedFilters.selectedRange.name : '';
  const Country = savedFilters.selectedCountry != null ? savedFilters.selectedCountry.iso2Code : '';
  const Warehouse = savedFilters.selectedWarehouse != null ? savedFilters.selectedWarehouse.code : '';
  const IncludeDiscontinuedProducts = savedFilters.includeDiscontinuedProducts;

  const savedProductList = useSelector((state) => state.SelectedProductCodeReducer);
  const allProductList = useSelector((state) => state.AllProductReducer);
  const selectedTemplate = useSelector((state) => state.TemplateReducer);

  //should be memoized or stable
  useEffect(() => {
    setLoading(true);
    if (Object.keys(savedProductList).length > 0) {
      setproductList(Object.values(allProductList));
      setRowSelection(savedProductList);
      setProductLength(Object.values(allProductList).length);
      setHasRows(() => !!Object.values(allProductList).length);
      setLoading(false);
    } else {
      getProductList();
    }
  }, []);


  const filterObj = {};
  filterObj['section'] = Section;
  filterObj['brand'] = Brand;
  filterObj['category'] = Category;
  filterObj['subCategory'] = SubCategory;
  filterObj['country'] = Country;
  filterObj['warehouse'] = Warehouse;
  filterObj['range'] = Range;
  filterObj['includeDiscontinuedProducts'] = IncludeDiscontinuedProducts;

  const getProductList = async () => {
    try {
      setLoading(true);
      let productList = await productsService.getProducts(filterObj);
      setProductLength(productList.length);
      setproductList(productList);
      setHasRows(() => !!productList.length)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response && !err.response.data.statusCode === 401) {
        dispatch(
          NotificationActions.addNotification({
            open: true,
            message: 'Something went wrong, please try again later.',
            type: 'error',
            timeout: 6000,
            typeStatus: 'Error'
          })
        );
      }
    }
  };

  const productColumns = useMemo(() => [
    {
      accessorKey: 'PrimaryImage72dpi', //access nested data with dot notation
      header: 'Primary Image',
      size: 30,
      enableColumnFilter: false,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex'
          }}>
          <img
            height={65}
            width={100}
            src={renderedCellValue}
            loading='lazy'
            style={{ margin: '0px auto' }}
          />
          {/* <Switch color="primary" onChange={(e) => { callAPI(e, renderedCellValue); }} >{renderedCellValue}</Switch> */}
        </Box>
      )
    },
    {
      accessorKey: 'ProductSku',
      header: 'Product SKU',
      size: 100,
      filterVariant: 'text',
      filterFn: 'contains',
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'SKU' },
    },
    {
      accessorKey: 'CatalogueTitle', //normal accessorKey
      header: 'Product Title',
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
          <ReadMore renderedCellValue={renderedCellValue} />
        </Box>
      ),
      enableColumnFilter: false
    },
    {
      accessorKey: 'CatalogueDescription',
      header: 'Product Description',
      size: 350,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
          <ReadMore renderedCellValue={renderedCellValue} />
        </Box>
      ),
      enableColumnFilter: false
    }
  ]);

  const assignProductCards = selectedProducts => {
    const productCardAllocation = templateService.assignProductCards(selectedTemplate.gridConfig, selectedProducts);
    dispatch(SequenceProductAction.saveSequence(productCardAllocation))
    handleClick();
  };

  async function selectProduct() {
    let selectedid = Object.keys(rowSelection);
    let selectedProducts = productList.filter((f) =>
      selectedid.some((selectedid) => selectedid === f.row_number)
    );

    dispatch(SelectedProductAction.saveSelectedProducts(selectedProducts));

    dispatch(AllProductAction.saveAllProducts(productList));

    dispatch(SelectedProductCodeAction.saveCode(rowSelection));

    let validateProducts = selectedProducts.filter(
      (product) =>
        !product.CatalogueTitle?.trim() ||
        !product.CatalogueDescription?.trim() ||
        !product.PrimaryImage300dpi?.trim() ||
        !product.PrimaryImage72dpi?.trim() 
    );
    if (Object.keys(validateProducts) == null || Object.keys(validateProducts).length == 0) {
       assignProductCards(selectedProducts);
    } else {
      let InvalidProductList = validateProducts.map((s) => s.ProductSku);
      setOpenRequiredDetailsDialog(true);
      setInvalidProducts(InvalidProductList);
    }
  }

  const handleBackClick = () => {
    dispatch(SelectedProductAction.resetSelectProducts());
    dispatch(AllProductAction.resetAllProducts());
    dispatch(SelectedProductCodeAction.resetSaveCode());
    handleBack();
  };

  const handleAddSKU = () => {
    searchProductFromList();
    setSearchSKUFocus();
    setFocusFlag(false);
  }

  const handleSearchSKU = (event) => {
    const searchSKU = event.target.value.trim();
    setSearchSKUField(searchSKU);
  }

  /**
   * Add Product by SKU API integration and validations handler.
   * @returns
   */
  const searchProductFromList = async () => {
    setLoading(true);
  
    const filterSKU = {
      productSKU: searchSKUField,
      country: Country,
      warehouse: Warehouse,
      includeDiscontinuedProducts: true
    };
    const [product] = (await productsService.getProducts(filterSKU));

    // No product found.
    if (!product) {
      setValidationMessage('SKU is either invalid or restricted in the selected region.')
      setIsDuplicateProduct(false);
      setOpenValidationDialog(true);

      setLoading(false);
      return;
    }

    // Check for duplicates.
    const duplicateProduct = productList.filter((prd) => prd.ProductSku === product.ProductSku);
    if (duplicateProduct.length > 0) {
      setDuplicateProduct(product);
      setIsDuplicateProduct(true);
      setOpenValidationDialog(true);
      setLoading(false);
      return;
    }

    //Check for discontinued product
    const isProductDiscontinued = !IncludeDiscontinuedProducts ? productsService.checkForDiscontinuedProducts(product, Warehouse) : null ;
    if(isProductDiscontinued) {
      setValidationMessage('SKU is restricted as include discontinued filter is inactive.');
      setIsDuplicateProduct(false);
      setOpenValidationDialog(true);
      setLoading(false);
      return;
    }

    // Add valid product to the list.
    addProductInList(product);

    setLoading(false);
    return;
  };

   const closeValidationDialog = () => {
    setOpenValidationDialog(false);
    setFocusFlag(true);
    setSearchSKUField('');
    if(searchSKUInputRef) {
      searchSKUInputRef.current.value = "";
    }
  };

  const addDuplicateProductInList = () => {
    addProductInList(duplicateProduct[0]);
    setOpenValidationDialog(false);
    setFocusFlag(false);
  }

  function addProductInList(product) {
    product["row_number"] = (productList.length + 1).toString();

    const cloneProductList = productList.map(obj => ({...obj}));
    cloneProductList.unshift(product);

    setproductList(cloneProductList);
    setProductLength(cloneProductList.length);
    setHasRows(() => !!cloneProductList.length)
    rowSelection[product.row_number] = true;

    dispatch(
      NotificationActions.addNotification({
        open: true,
        message: searchSKUField.toUpperCase() + ' added successfully',
        type: 'success',
        timeout: 6000,
        typeStatus: 'Success'
      })
    );
    setSearchSKUField('');
    searchSKUInputRef.current.value = "";
  }

  return (
    <div>
      <Box style={{ padding: '0px 50px 30px 60px' }}>
        <Grid container>
          <Grid item="true" xl={8} lg={8} md={12} sm={12}>
            {loading && <SimpleBackdrop loading={loading} />}
            {selectedTemplate.title != null ? (
              <p style={paragraphStyles}>
                {'Template :'} <span style={spanStyles}>{selectedTemplate.title}</span>
              </p>
            ) : (
              ''
            )}
            {savedFilters.selectedSection != null ? (
              <p style={paragraphStyles}>
                {'Section :'} <span style={spanStyles}>{savedFilters.selectedSection.name}</span>
              </p>
            ) : (
              ''
            )}
            {savedFilters.selectedCategory != null ? (
              <p style={paragraphStyles}>
                {'Category :'} <span style={spanStyles}>{savedFilters.selectedCategory.name}</span>
              </p>
            ) : (
              ''
            )}
            {savedFilters.selectedBrand != null ? (
              <p style={paragraphStyles}>
                {' '}
                {'Brand :'} <span style={spanStyles}>{savedFilters.selectedBrand.name}</span>
              </p>
            ) : (
              ''
            )}
          </Grid>
          <Grid item="true" xl={4} lg={4} md={12} sm={12} sx={{ justifyContent: 'end', display: 'flex', 
                '.addSKU-textbox': { 
                    width: '250px !important',
                    marginRight: '10px',
                    '.MuiOutlinedInput-root':{
                        height: '46px !important',
                    }
                  },
                  '.addSKU-button' : {
                      width: '136px !important',
                      height: '46px !important'
                    }
                  }} marginBottom={{ md: 0, sm: 0, lg: 1, xl: 1 }}>
            <BasicTextFields
                onDataChange={handleSearchSKU}
                inputValue={focusFlag ? searchSKUField : ""}
                inputType='text'
                placeholder="Add Product by SKU"
                inputRef={ !focusFlag ? searchSKUInputRef : undefined}
                max={50}
                className='addSKU-textbox'
            ></BasicTextFields>
            <ButtonComponent
              variant='contained'
              buttonText="Add Product"
              btnSize="small"
              disabled={searchSKUField == "" ? true : false }
              handleClick={handleAddSKU}
              className='addSKU-button'
             />
          </Grid>
        </Grid>
        { (hasRows === true) && 
          <Box>
            <Box
              sx={{
                '.MuiPaper-root': {
                  boxShadow: 'none'
                },
                '.MuiTableContainer-root': {
                  borderRadius: '4px',
                  maxHeight: 'clamp(350px, calc(100vh - 400px), 9999px)'
                },
                '.MuiCollapse-root': {
                  background: '#ffffff',
                  padding: '5px',
                  border: '1px solid #e5e5e5',
                  borderRadius: '4px',
                  marginTop: '10px'
                },
                '.MuiTableCell-root': {
                  '&:first-child': {
                    textAlign: 'center',
                    verticalAlign: 'middle !important'
                  },
                  verticalAlign: 'top',
                  padding: '8px'
                },
                '.Mui-TableHeadCell-Content': {
                  justifyContent: 'center'
                },
                '.MuiButtonBase-root': {
                  margin: '0px 0px 5px 2px'
                },
                '@media screen and (max-width: 991px)': {
                  '.MuiFormControl-root': {
                    minWidth: '0px !important'
                  }
                }
              }}>
              <MaterialReactTable
                tableInstanceRef={tableRef}
                columns={productColumns}
                data={productList}
                selectAllMode='all'
                enableStickyHeader={true}
                enableRowSelection
                onRowSelectionChange={setRowSelection}
                state={{ pagination, rowSelection }}
                enableColumnActions={false}
                enableSorting={false}
                enableTopToolbar={true}
                
                enableFacetedValues
                initialState={{
                  showColumnFilters: true
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: '1px solid #e5e5e5',
                    fontFamily: 'Brandon Grostesque Regular',
                    fontSize: '16px'
                  }
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: '1px solid #e5e5e5',
                    background: '#f1f1f1',
                    fontFamily: 'Brandon Grostesque Bold',
                    fontSize: '16px'
                  }
                }}
                muiSelectAllCheckboxProps={() => {
                  const selectedRowsCount = tableRef.current?.getSelectedRowModel().rows.length;
                  // set indeterminate State forcefully only if selected rows are less than TotalRowsCount
                  if (selectedRowsCount < productLength) {
                    return { indeterminate: (selectedRowsCount ? true : false) };
                  }
                }}
                muiTableContainerProps={{ sx: { maxHeight: '350px' } }}
                getRowId={(originalRow) => originalRow.row_number}
                onPaginationChange={setPagination}

                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableFullScreenToggle={true}
                enableHiding={false}
                positionToolbarAlertBanner={false}
                renderToolbarInternalActions={({ table }) => {
                  return (
                    <>
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  );
                }}
             
              />
            </Box>
            <RequiredDetailsDialog
              openRequiredDetailsDialog={openRequiredDetailsDialog}
              closeRequiredDetailsDialog={closeRequiredDetailsDialog}
              productsSKU={invalidProducts}
            />

          </Box>
        }
        { hasRows === false &&
        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '55vh'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Typography variant='h1' style={{ marginBottom: '30px' }}>No data matches your selected criteria!</Typography>
              <Typography variant='p'>
                Please update the search options on Select Filters page.
              </Typography>
            </div>
        </div>
        }
        { (hasRows === true || hasRows === false) &&
        <Grid container>
          <Grid item="true">
            <ButtonComponent
              variant='outlined'
              buttonText='Go Back'
              handleClick={handleBackClick}></ButtonComponent>
          </Grid>
          <Grid item="true" style={{ marginLeft: 'auto' }}>
            <ButtonComponent
              variant='contained'
              disabled={Object.keys(rowSelection).length > 0 ? false : true}
              buttonText={(<span>Select Products ({Object.keys(rowSelection).length})</span>)}
              handleClick={selectProduct}></ButtonComponent>
          </Grid>
        </Grid>
        }
        <ValidationDialog
          open={openValidationDialog}
          closeValidationDialog={closeValidationDialog}
          enteredSKU={searchSKUField}
          validationMessage={validationMessage}
          isDuplicateSKU={isDuplicateProduct}
          addProductValidationDialog={addDuplicateProductInList}>
        </ValidationDialog>
      </Box>
    </div>
  );
};

export default selectProductList;