import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

//Import all translation files
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';

//---Using different namespaces
const resources = {
  en: {
    translation: {
      ...en
    }
  },
  fr: {
    translation: {
      ...fr
    }
  }
};

i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: ['en', 'fr'],
    fallbackLng: 'en',
    load: 'currentOnly',
    preload: false
  });

export default i18next;
