import React, { useRef } from 'react';
import { Button, Typography, IconButton  } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function Fileupload({ id, label, selectFile, handleSelectedFile, invalidFormat, isRequired, handleCancelFile  }) {
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    handleSelectedFile(event);
  };

  const handleCancel = (event) => {
    handleCancelFile(event);
  }


  return (
    <div>
      <label
        htmlFor='upload-file'
        style={{
          fontFamily: 'Brandon Grostesque Bold',
          fontSize: '14px',
          color: invalidFormat ? '#ef4242' : '#333333',
          margin: '3px 0px'
        }}
      >
        {label} {isRequired ? <span style={{color: '#ef4242'}}> *</span> : ""}
      </label>
      <div
        id='file-title'
        style={{
          border: `1px solid ${invalidFormat ? '#ef4242' : 'rgba(0, 0, 0, 0.23)'}`,
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          position: 'relative',
          padding: '27px',
          marginTop: '3px',
          background: '#f2f2f2'
        }}
      >
        <p style={{ position: 'absolute', left: '10px', top: '0px', color: '#333333' }}>
          {selectFile == '' ? 'No file selected' : selectFile.substring(0, 25)}
        </p>
        {selectFile !== '' && (
          <div style={{ position: 'absolute', right: '161px', top: '0px' }}>
            <IconButton
              onClick={handleCancel}
              style={{
                padding: '15px 10px',
                borderRadius: '0',
                // color: '#ef4242',
                backgroundColor: '#f2f2f2'
              }}
              aria-label='Cancel'
            >
              <CancelOutlinedIcon />
            </IconButton>
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            right: '0',
            top: '0'
          }}
        >
          <label htmlFor='upload-file'>
            <input
              style={{ display: 'none' }}
              id={id}
              type='file'
              onChange={handleChange}
              ref={hiddenFileInput}
              accept="image/jpeg, image/jpg, image/png"
            />
            <Button
              sx={{
                borderRadius: '0px',
                padding: '13px 20px',
                textTransform: 'capitalize',
                fontSize: '16px',
                boxShadow: 'none',
                fontFamily: 'Brandon Grostesque Bold',
                'ipload-file-icon': {
                  color: '#3333333'
                },
                height: '54px !important'
              }}
              variant='contained'
              component='span'
              onClick={handleClick}
              startIcon={<FileUploadOutlinedIcon className='upload-file-icon' />}
            >
              Upload File
            </Button>{' '}
          </label>
        </div>
      </div>
      <Typography
        variant='caption'
        color={invalidFormat ? '#ef4242' : '#666666'}
        style={{
          fontFamily: 'Brandon Grostesque Regular',
          fontSize: '14px'
        }}
      >
        Allowed formats - jpeg, jpg & png
      </Typography>
    </div>
  );
}
