import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

/**
 * This is used for Laptop / Monitor screen with Stepper active stepslabel 
 * @param {*} props 
 * @returns Stepper 
 */
const StepperComponent = (props) => {
    return (
        <Stepper activeStep={props.value}>
        {props.stepList.map((label) => {
          const stepProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel className='stepper-label'>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };
  
  export default StepperComponent;