import { createSlice } from '@reduxjs/toolkit';

export const initialState = [];

export const SequenceProductSlice = createSlice({
  name: 'sequenceProduct',
  initialState: initialState,
  reducers: {
    saveSequence: (_state, action) => ({
      ...initialState,
      ...action.payload
    }),
    resetSequence: () => ({
      ...initialState
    })
  }
});

export const SequenceProductAction = SequenceProductSlice.actions;
export const SequenceProductReducer = SequenceProductSlice.reducer;