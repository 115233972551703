import React from 'react';

const Countries = () => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <h2>Countries Content</h2>
          <p>This is the content for Countries.</p>
        </div>
      </div>
    </>
  );
};
export default Countries;
