import axios from 'axios';
import { config } from '../config/config';

const getTemplateSuggestionsByProducts = async (products) => {
  try {
    return (await axios.post(`${config.api_url}/template/suggest-template`, { products })).data;
  } catch (e) {
    return [];
  }
};

const getTemplateList = async () => {
  return (await axios.get(`${config.api_url}/template/list`)).data;
};

/**
 * Assign PCs to the selected products as per choosen template.
 *
 * @param {*} gridConfig
 * @param {*} products
 * @returns
 */
const assignProductCards = (gridConfig, products) => {
  let mappedProducts = [];

  try {
    // Hardcoded prefix `-O1` as there's only one fixed `O1` for each PCs.
    const productCardStructure = gridConfig.reduce(
      (acc, { cardGroup, count }) => acc.concat(Array(count).fill(`${cardGroup}-O1`)),
      []
    );

    // Sort product based on sequence
    const sortedProducts = products.sort((a, b) => a.Sequence - b.Sequence);

    // Allocate product cards to product
    mappedProducts = sortedProducts.map((obj, index) => ({
      ...obj,
      Sequence: index + 1,
      ProductCardAllocation: productCardStructure[index % productCardStructure.length]
    }));
  } catch (err) {
    console.error(err);
  }

  return mappedProducts;
};

export default { getTemplateSuggestionsByProducts, getTemplateList, assignProductCards };
