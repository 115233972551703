import { createSlice } from '@reduxjs/toolkit';

export const allProductInitialState = [];

export const AllProductSlice = createSlice({
  name: 'allproduct',
  initialState: allProductInitialState,
  reducers: {
    saveAllProducts: (_state, action) => ({
      ...allProductInitialState,
      ...action.payload
    }),
    resetAllProducts: () => ({
      ...allProductInitialState
    })
  }
});

export const AllProductAction = AllProductSlice.actions;
export const AllProductReducer = AllProductSlice.reducer;
