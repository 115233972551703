import Dialog from '@mui/material/Dialog';
import ButtonComponent from '../../../../../components/button';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';

const RequiredDetailsDialog = (props) => {
    const { openRequiredDetailsDialog, closeRequiredDetailsDialog, productsSKU } = props;

    const productSkuList = [];
    productsSKU.forEach((product) => {
        productSkuList.push(<li key={product}>{product}</li>);
    });

    return (
        <Dialog
            open={openRequiredDetailsDialog}
            sx={{
                '.dialog-button': {
                    fontSize: '16px !important',
                    padding: '7px',
                    minWidth: '96px !important',
                    height: '36px !important',
                }
            }}>
            <div style={{ padding: '16px 24px' }}>
                <Typography variant='h2' style={{ marginBottom: '20px' }}>
                    Required Fields Missing
                </Typography>
                <Typography variant='p' style={{ marginBottom: '6px' }}>
                    One or more required fields are missing for the below Product SKU codes:
                </Typography>
                <p style={{ fontFamily: 'Brandon Grostesque Regular', fontSize: '12px', color: '#666666', margin: '3px 0' }}>(Required fields - Product Title, Primary Image - 72dpi and 300dpi, Product Description)</p>
                <p style={{ fontFamily: 'Brandon Grostesque Bold', fontSize: '16px' }}>Total Number of Products: <spna style={{ fontFamily: 'Brandon Grostesque Regular' }}>{productsSKU.length}</spna></p>
                <ul style={{ paddingLeft: '20px' }}>
                    {productSkuList}
                </ul>
                <Grid container>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <ButtonComponent
                            variant='contained'
                            handleClick={closeRequiredDetailsDialog}
                            buttonText='Close'
                            className='dialog-button'
                            btnSize="small"></ButtonComponent>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default RequiredDetailsDialog;
